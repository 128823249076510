import React from 'react'
import Notefication from '../Components/Notefication'
import { useSelector } from 'react-redux'
import Driverheader from './Driverheader'

const Ridermessage = () => {
  const driver = useSelector((state) => state?.data?.riderdata?.rider)

  return (
    <>
      <Driverheader />
      <Notefication usertype={"driver"} email={driver?.email} />
    </>
  )
}

export default Ridermessage