import React, { useEffect, useState } from 'react'
import Myheader from './Myheader'
import { Button, Card, CardContent, CircularProgress, Fab, Grid, IconButton, InputLabel, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import SearchFilter from "react-filter-search";
import axios from 'axios'
import { Apiurl } from '../api_urls'
import { useDispatch, useSelector } from 'react-redux';
import { getbookingdata, gettimecutdata, seteditmybooking } from '../redux_store/BookingDataSlice';
import { useNavigate } from 'react-router-dom';
import { GToaster } from './g_toaster';
import { CalendarMonth } from '@mui/icons-material';
import Gmodal from './Gmodal';
const toster = new GToaster()
const Newmybookings = () => {
    const [mymodaldata, setmymodaldata] = useState({
        modalshow: false,
        modaltype: "success",
        modalmessage: "raju"
    })
    const navi = useNavigate()
    let today = new Date();
    today.setDate(today.getDate() + 7)
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    let todaydate = yyyy + "-" + mm + "-" + dd;
    const [mydata, setmydata] = useState([])
    const [timecutdata, settimecutdata] = useState(null)
    const user = useSelector((state) => state.data.userdata.user)
    const [isloading, setisloading] = useState(false)
    const [inp2, setinp2] = useState("");
    const [filterdatamodal, setfilterdatamodal] = useState(false)
    const [btnloader, setbtnloader] = useState(false)
    const [btnid, setbtnid] = useState("")
    const dispatch = useDispatch()
    const userbookings = useSelector((state) => state.data.userbookings.bookingdata)

    const gettimecut = async () => {
        setisloading(true)
        await axios.get(Apiurl.GET_BOOKING_TIMECUT_URL).then((res) => {
            if (res.status === 200) {
                dispatch(gettimecutdata(res.data.data))
                settimecutdata(res.data.data)
                setisloading(false)
            } else {
                setisloading(false)
                mymodaldata["modalshow"] = true;
                mymodaldata["modaltype"] = "error";
                mymodaldata["modalmessage"] = `No Data Found`
                setmymodaldata({ ...mymodaldata })
            }
        }).catch((err) => {

            mymodaldata["modalshow"] = true;
            mymodaldata["modaltype"] = "error";
            mymodaldata["modalmessage"] = "Something went wrong cutout time fetch"
            setmymodaldata({ ...mymodaldata })

        })

    }

    const getUserBookingData = async () => {
        setisloading(true)
        await axios.get(`${Apiurl.GET_PARTICULAR_USER_NEW_BOOKING_LIST_URL}${user.id}`).then((res) => {

            if (res.data.status === 200) {
                setisloading(false)
                dispatch(getbookingdata(res.data.data))
                setmydata(res.data.data)
                setbtnloader(false)
            } else {
                setisloading(false)
                setbtnloader(false)
                setmydata([])
            }
        }).catch((err) => {
            setbtnloader(false)
            mymodaldata["modalshow"] = true;
            mymodaldata["modaltype"] = "error";
            mymodaldata["modalmessage"] = "Something went wrong Bookings data fetch"
            setmymodaldata({ ...mymodaldata })
        })

    }

    function EDIT(e) {
        dispatch(seteditmybooking(e));
        navi("/home")
    }

    async function del(e) {
        if (window.confirm(`Are you really want to cancel the booking of this time slot ${e.booked_date} and ${e.pickup_shift_time}`)) {
            setbtnloader(true)
            setbtnid(e.booking_id)
            await axios.post(`${Apiurl.CANCEL_BOOKING_URL}${e.booking_id}`).then((res) => {
                if (res.status === 200) {
                    getUserBookingData()
                    localStorage.removeItem("user")
                    localStorage.removeItem("username")
                    localStorage.removeItem("usercontact")
                    window.location.reload()
                }
            }).catch((err) => {
                setbtnloader(false)
                mymodaldata["modalshow"] = true;
                mymodaldata["modaltype"] = "error";
                mymodaldata["modalmessage"] = "Something error happend in cancel booking"
                setmymodaldata({ ...mymodaldata })
            });
        }
    }

    async function filterdata(e) {
        e.preventDefault();
        let data = new FormData(e.currentTarget);
        let startdate = data.get("startdate")
        let enddate = data.get("enddate")
        await axios.get(`${Apiurl.DATE_RANGE_FILTER_URL}${user.id}/${startdate}/${enddate}`).then((response) => {

            if (response.data.status === 200) {

                dispatch(getbookingdata(response.data.data))
                setmydata(() => [...response.data.booking])
                setfilterdatamodal(false)
            } else if (response.data.status === 400) {

                mymodaldata["modalshow"] = true;
                mymodaldata["modaltype"] = "error";
                mymodaldata["modalmessage"] = "There is no bookings available on the selected date"
                setmymodaldata({ ...mymodaldata })
            } else {

                mymodaldata["modalshow"] = true;
                mymodaldata["modaltype"] = "error";
                mymodaldata["modalmessage"] = "Server Error"
                setmymodaldata({ ...mymodaldata })
            }
        }).catch((Err) => {
            console.log(Err)
        })

    }

    function closemodal(e) {
        mymodaldata["modalshow"] = e
        mymodaldata["modalmessage"] = ""
        mymodaldata["modaltype"] = ""
        setmymodaldata({ ...mymodaldata })
    }




    useEffect(() => {
        getUserBookingData()
        gettimecut()
    }, [])


    return (
        <>
            <Myheader />
            {mymodaldata.modalshow == true && <Gmodal closemodal={closemodal} handledata={mymodaldata} />}
            <Grid container spacing={2}>

                <Grid item lg={3} sm={5} xs={8} sx={{ mt: 5 }}>
                    <TextField size="small" label="Search Here..." fullWidth value={inp2} onChange={(e) => setinp2(e.target.value)} />
                </Grid>
                <Grid item lg={9} sm={7} xs={4} sx={{ mt: 5, textAlign: "right", pr: 2 }}>
                    <Tooltip arrow title="Calendar">
                        <Fab size='small' variant="contained" color="secondary" onClick={() => setfilterdatamodal(true)} ><CalendarMonth /></Fab>
                    </Tooltip>
                </Grid>


                <Modal open={filterdatamodal} onClose={() => setfilterdatamodal(false)}>
                    <Grid item lg={4} xs={12} sx={{ m: "auto", mt: 25 }}>
                        <Card>
                            <CardContent>
                                <form onSubmit={filterdata}>
                                    <Grid container spacing={4}>
                                        <Grid item lg={12} xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h5" >
                                                Filter Your Booking List
                                            </Typography>
                                            <Button onClick={() => setfilterdatamodal(false)}>x</Button>
                                        </Grid>
                                        <Grid item lg={6} xs={6}>
                                            <InputLabel sx={{ pb: 1 }}>Select Start Date</InputLabel>
                                            <TextField fullWidth size="small" type="date" inputProps={{ max: todaydate, min: "2000-01-01" }} name="startdate" />
                                        </Grid>
                                        <Grid item lg={6} xs={6}>
                                            <InputLabel sx={{ pb: 1 }}>Select End Date</InputLabel>
                                            <TextField fullWidth size="small" type="date" inputProps={{ max: todaydate, min: "2000-01-01" }} name="enddate" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained" color="secondary" fullWidth>Submit</Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Modal>

                <Grid item lg={12} md={12} sm={12} xs={12} >
                    <Typography padding={2} variant="h4" style={{ textAlign: "center", py: 2 }}>Your Booking Details</Typography>
                    <Card>
                        {isloading ? <center><CircularProgress /></center>
                            :
                            mydata.length > 0 ?
                                <TableContainer style={{ maxHeight: "63vh" }}>
                                    <Table >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "17px" }} align="center">Booking Date</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "17px" }} align="center">Pickup Shift</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "17px" }} align="center">Shift Time</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "17px" }} align="center">Pickup Location</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "17px" }} align="center">Dropup Location</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "17px" }} align="center">Booking_Status</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "17px" }} align="center">Rider Name</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "17px" }} align="center">Rider Contact No.</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "17px" }} align="center">Action</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            <SearchFilter
                                                value={inp2}
                                                data={mydata}
                                                renderResults={(results) => (
                                                    <>
                                                        {results.map((row) => (
                                                            <TableRow key={row.booking_id}>
                                                                <TableCell align="center">{row?.booked_date}</TableCell>
                                                                <TableCell align="center">{row?.pickup_shift}</TableCell>
                                                                <TableCell align="center">{row?.pickup_shift_time}</TableCell>
                                                                <TableCell align="center">{row?.pickup_location?.slice(0, 40)}..</TableCell>
                                                                <TableCell align="center">{row?.dropup_location?.slice(0, 40)}..</TableCell>
                                                                <TableCell align="center">
                                                                    <Button
                                                                        style={{
                                                                            color: row.status == 2 ? "green" : row.status == 0 ? "red" : "orange", fontWeight: "bold",
                                                                        }}
                                                                    >
                                                                        {row.status == 2 ? "Booked" : row.status == 0 ? "Cancelled" : "Processing"}
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell align="center">{row.driver_name}</TableCell>
                                                                <TableCell align="center">{row.driver_mobile}</TableCell>
                                                                <TableCell align="center">
                                                                    {!(
                                                                        (row.booked_date == timecutdata?.datecut && timecutdata?.morning_status === 1 && row.pickup_shift === "Morning")
                                                                        || (row.booked_date == timecutdata?.datecut && timecutdata?.afternoon_status === 1 && row.pickup_shift === "Afternoon")
                                                                        || (row.booked_date == timecutdata?.datecut && timecutdata?.evening_status === 1 && row.pickup_shift === "Evening")
                                                                        || (row.booked_date == timecutdata?.datecut && timecutdata?.night_status === 1 && row.pickup_shift === "Night")
                                                                        || (row.status == 2)
                                                                    ) && <>

                                                                            {row.status == 1
                                                                                &&
                                                                                <>
                                                                                    <Button
                                                                                        sx={{ m: 1, fontSize: "12px" }}
                                                                                        onClick={() => EDIT(row)}
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        size="small"
                                                                                    >
                                                                                        Edit
                                                                                    </Button>
                                                                                    {(btnloader && btnid === row?.booking_id) ? <CircularProgress /> : <Button
                                                                                        sx={{ m: 1, fontSize: "12px" }}
                                                                                        onClick={() => del(row)}
                                                                                        variant="contained"
                                                                                        color="error"
                                                                                        size="small"
                                                                                    >Cancel
                                                                                    </Button>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </>}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </>
                                                )}
                                            />
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <center>
                                    No Data Found
                                </center>
                        }
                    </Card>
                </Grid>

            </Grid>

        </>
    )
}

export default Newmybookings