import { Notifications } from '@material-ui/icons'
import { Card, CardContent, Grid, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { customDateFormat } from './g_toaster'
import { Interweave } from 'interweave';
import axios from 'axios'
import { Apiurl } from '../api_urls'
import nodataimage from "../Components/img/nodata.png"




const Notefication = ({ usertype, email }) => {
    const [message, setmessage] = useState([])
    const notificationcounter = async () => {
        await axios.get(`${Apiurl.GET_NOTIFICATION_MESSAGE_URL}/${usertype}/${email}`).then((response) => {
            if (response.status === 200) {
                if (response.data.status === 200) {
                    setmessage(response.data.data)
                }
            } else {
                console.log("something went wrong in notifification")
            }
        })
    }

    useEffect(() => {
        notificationcounter()
    }, [])

    return (
        <Grid container spacing={5} sx={{ p: 5 }}>
            {message.length ? <>
                {message.map((ele, index) => (
                    <Grid key={index} item md={4} xs={12} >

                        <Card sx={{ borderRadius: 8 }}>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                                        <IconButton color='primary'>
                                            <Notifications />
                                        </IconButton> <Typography variant='h6' sx={{ textTransform: "capitalize", fontWeight: "bold", fontSize: "18px", px: 1 }}>{ele?.title ? ele?.title : "No Title Added"}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <hr />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography paragraph={true} textAlign={"justify"} variant='body1' padding={1}>
                                            <Interweave content={ele?.message} />

                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <hr />
                                    </Grid>
                                    <Grid item xs={12} sx={{ background: "#95c6dfd5", backgroundSize: "100%", borderRadius: 10 }}>
                                        <Typography textAlign={"right"} fontWeight={"bold"} paddingX={2} paddingY={1} color={"#117fb7"} >
                                            {customDateFormat(ele?.created, 'DD MMM YYYY h:mm:ss a')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                    </Grid>
                ))}
            </> : <>
                <Grid item md={12} xs={12} sx={{textAlign:"center",fontWeight:"bolder",fontFamily:"fantasy"}}>
                    <Typography variant='h2' align='center'>No Data Found</Typography>
                    <img src={nodataimage} />
                </Grid>

            </>}

        </Grid >
    )
}

export default Notefication