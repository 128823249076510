import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import car from "./img/sedan.png"
import GooglePlaces from "./GooglePlaces";
import { Apiurl } from "../api_urls";
import { useDispatch, useSelector } from "react-redux";
import Myheader from "./Myheader";
import Footer from "./Footer";
import { Fragment } from "react";
import { GToaster } from "./g_toaster";
import { ToastContainer } from "react-toastify";
import { seteditmybooking } from "../redux_store/BookingDataSlice";
import { CircularProgress } from "@mui/material";
import { Add, DoNotTouch, Forward } from "@mui/icons-material";
import Gmodal from "./Gmodal";
import Mymutiselect from "./Mymutiselect";
import { addalldates } from "../redux_store/BookingdateSlice";
import Termagree from "./Termagree";
import Downloadapp from "./Downloadapp";

const toster = new GToaster()

function Newbooking() {

    const [mymodaldata, setmymodaldata] = useState({
        modalshow: false,
        modaltype: "success",
        modalmessage: "raju"
    })

    const bookurl = Apiurl.ADD_BOOKING_URL;
    const user = useSelector((state) => state?.data?.userdata?.user);
    const editdata = useSelector((state) => state.data.userbookings.editbookindata);
    const [show, setshow] = useState(!editdata);
    const dispatch = useDispatch();

    let singletripobj = {
        'user_id': user?.id,
        'booking_pickup_type': "going",
        'name': user?.fullname,
        'mobile': user?.contact,
        'email': user?.email,
        'routine': user?.passenger_type,
        'agency_id': user?.distributor,
        'address': "",
        'pickup_droplocation': "",
        'factory': "",
        'pickup_date': [],
        'pickup_shift': "",
        'pickup_shift_time': "",
        "routine": "regular"
    }

    const retrunpartobj = {
        "dropup_dropaddress": "",
        'dropup_pickuplocation': "",
        'dropup_date': [],
        'dropup_shift_time': "",
        'dropup_shift': "",
        'dropup_factory': "",
    }

    const roundtrip = { ...singletripobj, ...retrunpartobj }
    const objdata = show ? { ...roundtrip } : { ...singletripobj }
    let data = editdata ? {
        ["booking_id"]: editdata?.booking_id,
        ['user_id']: user?.id,
        ['name']: user?.fullname,
        ['mobile']: user?.contact,
        ['email']: user?.email,
        ['routine']: user?.passenger_type,
        ['agency_id']: user?.distributor,
        ['address']: editdata?.address,
        ['pickup_droplocation']: editdata?.pickup_droplocation,
        ['factory']: editdata?.factory,
        ['pickup_shift']: editdata?.pickup_shift,
        ['pickup_shift_time']: editdata?.pickup_shift_time,

    } : { ...objdata }


    const [obj, setobj] = useState({ ...data })
    let navi = useNavigate();
    const [dlist, setdlist] = useState([])
    const [editaddress, seteditaddress] = useState(false);
    const [rteditdrop, setrteditdrop] = useState(false);
    const [rtpicaddress, setrtpicaddress] = useState(false)
    const [editdropupaddress, seteditdropupaddress] = useState(false);
    const [btnloader, setbtnloader] = useState(false);
    const [reconfirm, setreconfirm] = useState(false);
    const [alladdress, setalladdress] = useState([]);
    const [selectpickupdates, setselectpickupdates] = useState(false)
    const [selectdropupdates, setselectdropupdates] = useState(false)
    const [isagree, setisagree] = useState(false)
    const [isdownloadapp, setisdownloadapp] = useState(true)
    const [goingbookingavailabletime, setgoingbookingavailabletime] = useState([])
    const [returnbookingavailabletime, setreturnbookingavailabletime] = useState([])

    async function getpickupaddress() {
        await axios.get(`${Apiurl.GET_USER_ADDRESSES_NEW_URL}${user.id}/${"all"}`).then((res) => {
            if (res.data.status === 200) {
                let newdata = res.data.addresses
                setalladdress([...newdata])
                let pickup_location = newdata.find((ele) => ele.address == editdata?.pickup_location)
                let dropup_location = newdata.find((ele) => ele.address == editdata?.dropup_location)
                obj["address"] = pickup_location ? pickup_location : ""
                obj["pickup_droplocation"] = dropup_location ? dropup_location : ""
                setobj({ ...obj })
            }
        }).catch((err) => {
            mymodaldata["modalshow"] = true;
            mymodaldata["modaltype"] = "error";
            mymodaldata["modalmessage"] = "Something Went Wrong in Pickup Addresses fetch"
            setmymodaldata({ ...mymodaldata })
        })
    }

    async function gettimelistdata(e) {
        let dates = e.map((ele) => ele.value.split("-").reverse().join("-")).join("&")
        if (!!obj?.pickup_shift && obj?.address?.city && dates) {
            await axios.get(`${Apiurl.GET_BOOKING_SHIFT_TIME_URL}${obj?.pickup_shift}_${obj?.address?.city}_${dates}`).then((response) => {
                if (response.status == 200) {
                    if (response.data.status == 200) {
                        setgoingbookingavailabletime(() => [...response.data.data])
                    } else if (response.data.status == 400) {
                        mymodaldata["modalshow"] = true;
                        mymodaldata["modaltype"] = "error";
                        mymodaldata["modalmessage"] = `We cannot provide ride for this city`
                        setmymodaldata({ ...mymodaldata })


                    } else {
                        mymodaldata["modalshow"] = true;
                        mymodaldata["modaltype"] = "error";
                        mymodaldata["modalmessage"] = `You can't Book the ride for this ${response.data.data} date and shift`
                        setmymodaldata({ ...mymodaldata })


                    }
                } else {
                    mymodaldata["modalshow"] = true;
                    mymodaldata["modaltype"] = "error";
                    mymodaldata["modalmessage"] = `Server Error`
                    setmymodaldata({ ...mymodaldata })

                }
            }).catch((err) => {
                mymodaldata["modalshow"] = true;
                mymodaldata["modaltype"] = "error";
                mymodaldata["modalmessage"] = `Something went wrong in timecutout status fetch`
                setmymodaldata({ ...mymodaldata })

            })
        }


    }

    function Mydate() {
        axios.get(`${Apiurl.GET_BOOKING_DAYS_URL}`).then((succ) => {
            let response = succ.data.days.map((ele) => {
                return { ...ele, ["checkdata"]: false }
            })
            dispatch(addalldates([...response]))
            setdlist([...response]);
            if (editdata) {
                let mydata = response.filter((ele) => ele.value === editdata?.booked_date.split("-").reverse().join("-"));
                if (mydata.length == 0) {
                    setobj({ ...obj, ["pickup_date"]: [], ["pickup_shift"]: "", ["pickup_shift_time"]: "" })
                    setdlist([...response]);
                } else {
                    setobj({
                        ...obj, ["pickup_date"]: [...mydata].map((ele) => {
                            return { ...ele, ["checkdata"]: true }
                        })
                    })
                    setdlist([...response]);
                    gettimelistdata(mydata)
                }
            }
        })
    }

    function handlechange(key, value) {
        setobj({ ...obj, [key]: value })
    }

    function gopicadress(e, zip, city, label,lat,long) {
        let mydata = JSON.parse(JSON.stringify(obj))
        let myobj = {
            ['id']: 0, ["address"]: label, ["tag"]: "Home", ["postal_code"]: zip, ["city"]: city, ['type']: "pickup",
            ["lat"]: lat,
            ["long"]: long,

        }
        mydata["address"] = myobj
        mydata["pickup_date"] = []
        mydata["pickup_shift"] = ""
        mydata["pickup_shift_time"] = ""
        setobj({ ...mydata })
    }

    function godropvalue(e, zip, city, label,lat,long) {
        let mydata = JSON.parse(JSON.stringify(obj))
        let myobj = {
            ['id']: 0, ["address"]: label, ["tag"]: "factory", ["postal_code"]: zip, ["city"]: city, ["type"]: "dropup",
            ["lat"]: lat,
            ["long"]: long,
            
           
        }
        mydata["pickup_droplocation"] = myobj
        setobj({ ...mydata })
    }

    function retdropaddress(e, zip, city, label,lat,long) {
        let mydata = JSON.parse(JSON.stringify(obj))
        let myobj = {
            ['id']: 0, ["address"]: label, ["tag"]: "Home", ["postal_code"]: zip, ["city"]: city, ["type"]: "dropup",
            ["lat"]: lat,
            ["long"]: long,
        }
        mydata["dropup_dropaddress"] = myobj
        setobj({ ...mydata })
    }

    function rtpicvalue(e, zip, city, label,lat,long) {
        let mydata = JSON.parse(JSON.stringify(obj))
        let myobj = {
            ['id']: 0, ["address"]: label, ["tag"]: "factory", ["postal_code"]: zip, ["city"]: city, ['type']: "pickup",
            ["lat"]: lat,
            ["long"]: long,
        }
        mydata["dropup_pickuplocation"] = myobj;
        mydata["dropup_date"] = [];
        mydata["dropup_shift"] = "";
        mydata["dropup_shift_time"] = "";

        setobj({ ...mydata })
    }


    function handlefactorychange(e) {
        let newdata = JSON.parse(JSON.stringify(obj))
        newdata['dropup_factory'] = e
        newdata['factory'] = e
        setobj({ ...newdata })
    }


    async function goingtimecutoutstatus(key, val) {
        let boolval = "";
        let dates = Array.isArray(obj?.pickup_date) ? obj?.pickup_date.map((ele) => ele.value.split("-").reverse().join("-")).join("&") : obj?.pickup_date.split("-").reverse().join("-")
        await axios.get(`${Apiurl.GET_BOOKING_SHIFT_TIME_URL}${val}_${obj?.address?.city}_${dates}`).then((response) => {
            if (response.status == 200) {
                if (response.data.status == 200) {
                    setgoingbookingavailabletime(() => [...response.data.data])
                    boolval = true;
                } else if (response.data.status == 400) {
                    mymodaldata["modalshow"] = true;
                    mymodaldata["modaltype"] = "error";
                    mymodaldata["modalmessage"] = "Can you please choose another Address because we are not Providing service in this City."
                    setmymodaldata({ ...mymodaldata })
                    setgoingbookingavailabletime(() => [])
                    boolval = false;
                } else {
                    mymodaldata["modalshow"] = true;
                    mymodaldata["modaltype"] = "error";
                    mymodaldata["modalmessage"] = `Booking Time Is Already Lapsed for ${response.data.data.split("_").join("-")}`
                    setmymodaldata({ ...mymodaldata })
                    setgoingbookingavailabletime(() => [])
                    boolval = false;

                }
            } else {
                mymodaldata["modalshow"] = true;
                mymodaldata["modaltype"] = "error";
                mymodaldata["modalmessage"] = `Server Error`
                setmymodaldata({ ...mymodaldata })
                boolval = false;


            }
        }).catch((err) => {
            mymodaldata["modalshow"] = true;
            mymodaldata["modaltype"] = "error";
            mymodaldata["modalmessage"] = "Something went wrong in timecutout status fetch"
            setmymodaldata({ ...mymodaldata })
            boolval = false;

        })
        return boolval
    }

    async function retruntimecutoutstatus(key, val) {
        let boolval = "";
        let dates = Array.isArray(obj?.dropup_date) ? obj?.dropup_date.map((ele) => ele.value.split("-").reverse().join("-")).join("&") : obj?.dropup_date.split("-").reverse().join("-")
        await axios.get(`${Apiurl.GET_BOOKING_SHIFT_TIME_URL}${val}_${obj?.dropup_pickuplocation?.city.trim()}_${dates}`).then((response) => {
            if (response.status == 200) {
                if (response.data.status == 200) {
                    setreturnbookingavailabletime(() => [...response.data.data])
                    boolval = true;
                } else if (response.data.status == 400) {
                    mymodaldata["modalshow"] = true;
                    mymodaldata["modaltype"] = "error";
                    mymodaldata["modalmessage"] = "Can you please choose another Address because we are not Providing service in this City."
                    setmymodaldata({ ...mymodaldata })
                    boolval = false;
                } else {
                    mymodaldata["modalshow"] = true;
                    mymodaldata["modaltype"] = "error";
                    mymodaldata["modalmessage"] = `Booking Time Is Already Lapsed for ${response.data.data.split("_").join("-")}`
                    setmymodaldata({ ...mymodaldata })
                    boolval = false;
                }
            } else {
                mymodaldata["modalshow"] = true;
                mymodaldata["modaltype"] = "error";
                mymodaldata["modalmessage"] = `Server Error`
                setmymodaldata({ ...mymodaldata })
                boolval = false;
            }
        }).catch((err) => {
            mymodaldata["modalshow"] = true;
            mymodaldata["modaltype"] = "error";
            mymodaldata["modalmessage"] = "Something went wrong in timecutout status fetch"
            setmymodaldata({ ...mymodaldata })
            boolval = false;
        })
        return boolval
    }


    async function checkbooking() {
        let newdata = JSON.parse(JSON.stringify(obj))
        let boleanval = ""
        let myreturnvalue1 = goingtimecutoutstatus("pickup_shift", newdata?.pickup_shift)
        let myreturnvalue2 = retruntimecutoutstatus("dropup_shift", newdata?.dropup_shift)
        if (show) {
            let newval1 = await myreturnvalue1
            let newval2 = await myreturnvalue2
            boleanval = newval1 && newval2
        } else {
            let newval1 = await myreturnvalue1
            boleanval = newval1
        }

        if (boleanval) {
            return true
        } else {
            newdata["pickup_shift"] = ""
            newdata["pickup_shift_time"] = ""
            newdata["dropup_shift"] = ""
            newdata["dropup_shift_time"] = ""
            setobj({ ...newdata })
            return false
        }
    }

    async function changestatus() {
        let data = await checkbooking()
        if (data) {
            setreconfirm(true)
        }
    }


    async function bookingdone() {
        let newdata = JSON.parse(JSON.stringify(obj))
        setbtnloader(true)
        await (editdata ? axios.post(Apiurl.UPDATE_BOOKING_URL, newdata) : axios.post(bookurl, newdata)).then((succ) => {
            dispatch(seteditmybooking(null))
            setbtnloader(false)
            mymodaldata["modalshow"] = true;
            mymodaldata["modaltype"] = "success";
            mymodaldata["modalmessage"] = `Booking Successfully ${editdata ? "Updated" : "Added"}`
            setmymodaldata({ ...mymodaldata })
            setobj({ ...objdata })
            localStorage.removeItem("user")
            localStorage.removeItem("username")
            localStorage.removeItem("usercontact")
            navi("/mybookings")
            setreconfirm(false)
            window.location.reload()
        }).catch((err) => {
            mymodaldata["modalshow"] = true;
            mymodaldata["modaltype"] = "error";
            mymodaldata["modalmessage"] = "Something Went Wrong in Add New Booking"
            setmymodaldata({ ...mymodaldata })
            setbtnloader(false)
        })

    }


    async function bookdata(e) {
        e.preventDefault();
        let data = await checkbooking();
        if (data) {
            bookingdone();
        }
    }

    function closemodal(e) {
        mymodaldata["modalshow"] = e
        mymodaldata["modalmessage"] = ""
        mymodaldata["modaltype"] = ""
        setmymodaldata({ ...mymodaldata })
    }

    function pickupdatechange(e) {
        let newobj = JSON.parse(JSON.stringify(obj))
        newobj['pickup_date'] = e.filter((ele) => ele?.checkdata)
        newobj["pickup_shift"] = ""
        newobj["pickup_shift_time"] = ""
        setobj({ ...newobj })
    }

    function dropupdatechange(e) {
        let newobj = JSON.parse(JSON.stringify(obj))
        newobj["dropup_date"] = e.filter((ele) => ele?.checkdata);
        newobj["dropup_shift"] = "";
        newobj["dropup_shift_time"] = "";
        setobj({ ...newobj })
    }

    function checkgoingaddress(key, e) {
        let newdata = JSON.parse(JSON.stringify(obj))
        let data = JSON.parse(e.target.value)
        if ((newdata[`address`]?.address === data?.address || newdata[`pickup_droplocation`]?.address === data?.address) && (newdata[`address`]?.address?.length > 0 || newdata[`pickup_droplocation`]?.address?.length > 0)) {
            mymodaldata["modalshow"] = true;
            mymodaldata["modaltype"] = "error";
            mymodaldata["modalmessage"] = "Pickup and Dropup address cannot be same"
            setmymodaldata({ ...mymodaldata })
        } else {
            if (key == "address") {
                if (show) {
                    newdata[key] = data;
                    newdata[`dropup_dropaddress`] = data
                    newdata["pickup_date"] = []
                    newdata["pickup_shift"] = ""
                    newdata["pickup_shift_time"] = ""
                    newdata["dropup_date"] = []
                    newdata["dropup_shift"] = ""
                    newdata["dropup_shift_time"] = ""
                    setobj({ ...newdata })
                } else {
                    newdata[key] = data;
                    newdata["pickup_date"] = []
                    newdata["pickup_shift"] = ""
                    newdata["pickup_shift_time"] = ""
                    setobj({ ...newdata })
                }

            }
            if (key === "pickup_droplocation") {
                if (show) {
                    newdata[key] = data;
                    newdata[`dropup_pickuplocation`] = data;
                    newdata["dropup_date"] = []
                    newdata["dropup_shift"] = ""
                    newdata["dropup_shift_time"] = ""
                    setobj({ ...newdata })

                } else {
                    newdata[key] = data
                    setobj({ ...newdata })
                }
            }
        }




    }

    function checkreturnaddress(key, e) {
        let newdata = JSON.parse(JSON.stringify(obj));
        let data = JSON.parse(e.target.value);
        if ((newdata[`dropup_pickuplocation`]?.address === data?.address || newdata[`dropup_dropaddress`]?.address === data?.address) && (newdata[`dropup_pickuplocation`]?.address?.length > 0 || newdata[`dropup_dropaddress`]?.address?.length > 0)) {
            mymodaldata["modalshow"] = true;
            mymodaldata["modaltype"] = "error";
            mymodaldata["modalmessage"] = "Return Pickup and Dropup address cannot be same"
            setmymodaldata({ ...mymodaldata })
        } else {
            if (key === "dropup_pickuplocation") {
                newdata[key] = data;
                newdata["dropup_date"] = [];
                newdata["dropup_shift"] = "";
                newdata["dropup_shift_time"] = "";
                setobj({ ...newdata })
            }
            if (key === "dropup_dropaddress") {
                newdata[key] = data
                setobj({ ...newdata })
            }
        }

    }

    function changeagreestate(e) {
        setisagree(e)
    }

    function pickupchangedates(e) {
        setselectpickupdates(e)
    }

    function dropupchangedates(e) {
        setselectdropupdates(e)
    }

    function closedownload(e) {
        setisdownloadapp(e)
    }

    useEffect(() => {
        if (!user) {
            navi("/")
        } else {
            getpickupaddress()
            Mydate();
        }
    }, [])



    return (
        <>
            <Myheader />
            {isdownloadapp ? <>
                <Downloadapp isdownload={isdownloadapp} closedownload={closedownload} usertype={"user"} />
            </> : <>
                {isagree ? <>
                    {mymodaldata.modalshow == true && <Gmodal closemodal={closemodal} handledata={mymodaldata} />}
                    <section className="track-area py-5" id="booking" >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="tab quote-list-tab py-3">
                                        <ul className="tabs">
                                            <li className="p-2 d-flex align-items-center" >
                                                <img src={car} className="img-fluid carimg" />
                                                <a className="ps-2">
                                                    Book Your service
                                                </a>
                                            </li>
                                        </ul>

                                        <div className="tab_content">
                                            <div className="tabs_item">
                                                {!editdata &&
                                                    <Fragment>
                                                        <button className={show ? ("btn btn-lg btn-default tab-btn mb-3 active") : ("btn btn-lg btn-default  tab-btn mb-3")} onClick={() => {
                                                            setshow(true)
                                                            setobj({ ...roundtrip })
                                                        }}>Round Trip</button>
                                                        <button className={show ? ("btn btn-lg btn-default tab-btn mb-3 ") : ("btn btn-lg btn-default  tab-btn mb-3 active")} onClick={() => {
                                                            setshow(false)
                                                            setobj({ ...singletripobj })
                                                        }} >Single Side</button>
                                                    </Fragment>
                                                }
                                                <form id="bookingForm" onSubmit={bookdata}>
                                                    <div className="row" style={reconfirm ? { cursor: "not-allowed", pointerEvents: "none" } : {}}>
                                                        <div className="col-lg-12">
                                                            <div className="row row1 mt-2 py-2">
                                                                <h2 className="formhead">Personal Details</h2>
                                                                <div className="col-lg-4 col-sm-6 mb-2">
                                                                    <label className="form-label">Name</label>
                                                                    <input type="text" className="form-control" value={obj?.name} readOnly />
                                                                </div>
                                                                <div className="col-lg-4 col-sm-6 mb-2">
                                                                    <label className="form-label">Email</label>
                                                                    <input type="email" className="form-control " value={obj?.email} readOnly />
                                                                </div>
                                                                <div className="col-lg-4 col-sm-6 mb-2">
                                                                    <label className="form-label">Contact</label>
                                                                    <input type="tel" className="form-control" value={obj?.mobile} readOnly />
                                                                </div>

                                                            </div>

                                                            <div className="row row1 mt-2">
                                                                <h2 className="formhead">{show && `Going`} Details</h2>
                                                                <div className="col-lg-4 col-sm-6 mb-4">
                                                                    <label className="form-label">{editaddress ? "Pickup Address" : "Select Pickup Address"}</label>
                                                                    <span onClick={() => seteditaddress(!editaddress)} style={{ color: "#117fb7", paddingLeft: "18px", fontSize: "16px", cursor: "pointer", fontWeight: "bolder" }}><u><Add />{editaddress ? "Old" : "New"} Address</u></span>
                                                                    {editaddress ? (<>
                                                                        <GooglePlaces gopicadress={gopicadress} label={"gopicadress"} />
                                                                    </>) : (<>
                                                                        <select className="form-select form-control" required id="pickadress"
                                                                            value={JSON.stringify(obj?.address)}
                                                                            name="pickadress" onChange={(e) => checkgoingaddress("address", e)}>
                                                                            <option defaultValue={""} className="form-control" ></option>
                                                                            {alladdress.map((row) => {
                                                                                return (<React.Fragment key={row.id}>
                                                                                    <option value={JSON.stringify(row)} >
                                                                                        {row.address}
                                                                                    </option>
                                                                                </React.Fragment>)
                                                                            })}
                                                                        </select>
                                                                    </>)}
                                                                </div>
                                                                <div className="col-lg-4 col-sm-6 mb-4">
                                                                    <label className="form-label">Company/office/shop/building Name</label>
                                                                    <input type="text" className="form-control" name="factory" value={obj?.factory} onChange={(e) => show ? handlefactorychange(e.target.value) : handlechange("factory", e.target.value)} required />
                                                                </div>
                                                                <div className="col-lg-4 col-sm-6 mb-4">
                                                                    <label className="form-label">{editdropupaddress ? "Drop Address" : "Select Drop Address"}  </label>
                                                                    <span onClick={() => seteditdropupaddress(!editdropupaddress)} style={{ color: "#117fb7", paddingLeft: "18px", fontSize: "16px", cursor: "pointer", fontWeight: "bolder" }}><u><Add />{editdropupaddress ? "Old" : "New"} Address</u></span>
                                                                    {editdropupaddress ? (<>
                                                                        <GooglePlaces godropvalue={godropvalue} label={"godropvalue"} />
                                                                    </>) : (<>
                                                                        <select className="form-select form-control" required id="godropvalue" name="godropvalue"
                                                                            value={JSON.stringify(obj?.pickup_droplocation)}
                                                                            onChange={(e) => checkgoingaddress("pickup_droplocation", e)}
                                                                        >
                                                                            <option defaultValue={""} className="form-control" ></option>
                                                                            {alladdress.map((row) => {
                                                                                return (<React.Fragment key={row.id}>
                                                                                    <option value={JSON.stringify(row)} >
                                                                                        {row.address}
                                                                                    </option>
                                                                                </React.Fragment>)
                                                                            })}
                                                                        </select>
                                                                    </>)}
                                                                </div>
                                                                <div className="col-lg-4 col-sm-6 mb-4">
                                                                    <label className="form-label">Select Pickup Date </label>
                                                                    <Mymutiselect
                                                                        allchange={pickupchangedates}
                                                                        selectall={selectpickupdates}
                                                                        options={dlist}
                                                                        disabled={!(!!obj?.address)}
                                                                        value={obj?.pickup_date !== undefined ? obj?.pickup_date : []}
                                                                        handlechange={pickupdatechange}
                                                                    />

                                                                    {editdata && <span>{editdata?.booked_date}</span>}

                                                                </div>
                                                                <div className="col-lg-4 col-sm-6 mb-4">
                                                                    <label className="form-label">Select Shift <span style={{ fontSize: "12px" }}>Please Select Date First</span></label>
                                                                    <select disabled={obj?.pickup_date?.length < 1 || !(!!obj?.pickup_date)} className="form-select form-control" required value={obj?.pickup_shift}
                                                                        onChange={(e) => {
                                                                            obj["pickup_shift"] = e.target.value
                                                                            obj["pickup_shift_time"] = ""
                                                                            setobj({ ...obj })
                                                                            goingtimecutoutstatus("pickup_shift", e.target.value)
                                                                        }}
                                                                    >
                                                                        <option defaultValue={" "} className="form-control" ></option>
                                                                        <>
                                                                            <option >Morning</option>
                                                                            <option >Afternoon</option>
                                                                            <option >Evening</option>
                                                                            <option >Night</option>
                                                                        </>
                                                                    </select>
                                                                    {editdata && <span>{editdata?.pickup_shift}</span>}
                                                                </div>

                                                                <div className="col-lg-4 col-sm-6 mb-4">
                                                                    <label className="form-label">Select Shift Time <span style={{ fontSize: "12px" }}>Please Select Shift First</span></label>
                                                                    <select disabled={!(!!obj?.pickup_shift)} value={obj?.pickup_shift_time} className="form-select form-control" required id="shift" onChange={(e) => handlechange("pickup_shift_time", e.target.value)} name="schedules">
                                                                        <option defaultValue={""} className="form-control" ></option>
                                                                        {goingbookingavailabletime.map((row) => {
                                                                            return (<React.Fragment key={row.id}>
                                                                                <option value={`${row.timing} ${row.time_format}`}>
                                                                                    {row.timing} - {row.time_format}
                                                                                </option>
                                                                            </React.Fragment>)
                                                                        })}
                                                                    </select>
                                                                    {editdata && <span>{editdata?.pickup_shift_time}</span>}
                                                                </div>
                                                                {show && <>
                                                                    <h2 className="formhead">Return Details</h2>

                                                                    <div className="col-lg-4 col-sm-6 mb-4">
                                                                        <label className="form-label">{rtpicaddress ? "Return Pickup Address" : "Select Return Pickup Address"}  </label>
                                                                        <span onClick={() => setrtpicaddress(!rtpicaddress)} style={{ color: "#117fb7", paddingLeft: "18px", fontSize: "16px", cursor: "pointer", fontWeight: "bolder" }}><u><Add />{rtpicaddress ? "Old" : "New"} Address</u></span>
                                                                        {rtpicaddress ? (<>
                                                                            <GooglePlaces rtpicvalue={rtpicvalue} label={"rtpicvalue"} />
                                                                        </>) : (<>
                                                                            <select className="form-select form-control" required id="godropvalue" name="godropvalue"
                                                                                value={JSON.stringify(obj?.dropup_pickuplocation)}
                                                                                onChange={(e) => checkreturnaddress("dropup_pickuplocation", e)}
                                                                            >
                                                                                <option defaultValue={""} className="form-control" ></option>
                                                                                {alladdress.map((row) => {
                                                                                    return (<React.Fragment key={row.id}>
                                                                                        <option value={JSON.stringify(row)} >
                                                                                            {row.address}
                                                                                        </option>
                                                                                    </React.Fragment>)
                                                                                })}
                                                                            </select>
                                                                        </>)}
                                                                    </div>

                                                                    <div className="col-lg-4 col-sm-6 mb-4">
                                                                        <label className="form-label">Company/office/shop/building Name</label>
                                                                        <div className="input-group mb-2">
                                                                            <input type="text" className="form-control newinput" value={obj?.dropup_factory} onChange={(e) => handlechange("dropup_factory", e.target.value)} required />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-4 col-sm-6 mb-4">
                                                                        <label className="form-label">{rteditdrop ? "Return Drop Address" : "Select Return Drop Address"} </label>
                                                                        <span onClick={() => setrteditdrop(!rteditdrop)} style={{ color: "#117fb7", paddingLeft: "18px", fontSize: "16px", cursor: "pointer", fontWeight: "bolder" }}><u><Add />{rteditdrop ? "Old" : "New"} Address</u></span>
                                                                        {rteditdrop ? (<>
                                                                            <GooglePlaces retdropaddress={retdropaddress} label={"retdropaddress"} />
                                                                        </>) : (<>
                                                                            <select className="form-select form-control" required id="godropvalue" name="godropvalue"
                                                                                value={JSON.stringify(obj?.dropup_dropaddress)}
                                                                                onChange={(e) => checkreturnaddress("dropup_dropaddress", e)}
                                                                            >
                                                                                <option defaultValue={""} className="form-control" ></option>
                                                                                {alladdress.map((row) => {
                                                                                    return (<React.Fragment key={row.id}>
                                                                                        <option value={JSON.stringify(row)} >
                                                                                            {row.address}
                                                                                        </option>
                                                                                    </React.Fragment>)
                                                                                })}
                                                                            </select>
                                                                        </>)}
                                                                    </div>
                                                                    <div className="col-lg-4 col-sm-6 mb-4">
                                                                        <label className="form-label">Return Date</label>
                                                                        <Mymutiselect
                                                                            allchange={dropupchangedates}
                                                                            selectall={selectdropupdates}
                                                                            options={dlist}
                                                                            disabled={!(!!obj?.dropup_pickuplocation)}
                                                                            value={obj?.dropup_date !== undefined ? obj?.dropup_date : []}
                                                                            handlechange={dropupdatechange}

                                                                        />

                                                                    </div>

                                                                    <div className="col-lg-4 col-sm-6 mb-4">
                                                                        <label className="form-label">When You Are Coming Back ? <span style={{ fontSize: "12px" }}>Please Select Date First</span> </label>
                                                                        <select className="form-select form-control" required name="reschudle"
                                                                            disabled={obj?.dropup_date?.length < 1 || !(!!obj?.dropup_date)}
                                                                            value={obj?.dropup_shift} onChange={(e) => {
                                                                                obj["dropup_shift"] = e.target.value
                                                                                obj["dropup_shift_time"] = ""
                                                                                setobj({ ...obj })
                                                                                retruntimecutoutstatus("dropup_shift", e.target.value)
                                                                            }}>
                                                                            <option defaultValue={" "} className="form-control" ></option>
                                                                            <>
                                                                                <option >Morning</option>
                                                                                <option >Afternoon</option>
                                                                                <option >Evening</option>
                                                                                <option >Night</option>
                                                                            </>
                                                                        </select>
                                                                    </div>

                                                                    <div className="col-lg-4 col-sm-6 mb-4">
                                                                        <label className="form-label">Return Timing <span style={{ fontSize: "12px" }}>Please Select Shift First</span></label>
                                                                        <select disabled={!(!!obj?.dropup_shift)} value={obj?.dropup_shift_time} className="form-select form-control"
                                                                            onChange={(e) => handlechange("dropup_shift_time", e.target.value)} required name="retiming" id="rtshift">
                                                                            <option defaultValue={""} className="form-control" ></option>
                                                                            {returnbookingavailabletime.map((row) => {
                                                                                return (<React.Fragment key={row.id}>
                                                                                    <option value={`${row.timing} ${row.time_format}`}>
                                                                                        {row.timing} - {row.time_format}
                                                                                    </option>
                                                                                </React.Fragment>)
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </>}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row row1 ">
                                                        {reconfirm ?
                                                            <div>
                                                                <div style={{ paddingBottom: "18px", fontSize: "15px" }}> <DoNotTouch /> For Continue Form Filling Cick On <Forward /> Continue Edit Button</div>
                                                                <button type="button" onClick={() => setreconfirm(false)} style={{ fontSize: "18px" }} className="btn btn-primary text-center me-3">
                                                                    Edit
                                                                </button>
                                                                <button type="submit" style={{ fontSize: "18px" }} className="btn btn-success text-center">
                                                                    {btnloader ? <CircularProgress sx={{ color: "white" }} size={30} /> : "Confirm"}
                                                                </button>
                                                            </div> :
                                                            <center>
                                                                <div className="col-lg-8 col-sm-8 col-12 mt-3">
                                                                    <button type="button" onClick={changestatus} className="default-btn text-center">
                                                                        Reconfirm Your Booking Details
                                                                    </button>
                                                                </div>
                                                            </center>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </> : <>
                    <Termagree isagree={isagree} usertype={"passenger"} handlechange={changeagreestate} />

                </>}
            </>}


        </>
    )

}

export default Newbooking; 