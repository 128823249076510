import { createSlice } from "@reduxjs/toolkit";

const Shiftavailabilty = createSlice({
    name: "Shiftavailabilty",
    initialState: {
        oldshiftdata: []
    },
    reducers: {
        getoldshiftdata(state, action) {
            state.oldshiftdata = action.payload
        }
    }
})

export default Shiftavailabilty.reducer
export const { getoldshiftdata } = Shiftavailabilty.actions