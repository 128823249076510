import React from "react";
import "./App.css"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Login from "./Components/Login";
import Register from "./Components/Register";
import RiderRegister from "./DriverComponent/RiderRegister";
import Riderlogin from "./DriverComponent/RiderLogin";
import RiderHome from "./DriverComponent/RiderHome";
import Driverprofile from "./DriverComponent/Driverprofile";
// import Driveredit from "./DriverComponent/Driveredit";
import LoginType from "./Components/Logintype";
import TngLogin from "./Components/TngLogin";
import Newbooking from "./Components/Newbooking";
import Newmybookings from "./Components/Newmybookings";
import Newprofile from "./Components/Newprofile";
import Contactus from "./Contactus";
import Privacypolicy from "./Privacypolicy";
import { ToastContainer } from "react-toastify";
import Driveredit from "./DriverComponent/Driveredit";
import Address from "./Components/Address";
import RiderAddress from "./DriverComponent/RiderAddress";
import DriverShiftAvailablity from "./DriverComponent/DriverShiftAvailablity";

import Usermessage from "./Components/Usermessage";
import Ridermessage from "./DriverComponent/Ridermessage";
import Usersupport from "./Components/Usersupport";
import Ridersupport from "./DriverComponent/Ridersupport";




function App() {

    return (
        <>

            <ToastContainer />
            <Router>
                <Routes>
                    {/*================= userpanel========================= */}

                    <Route path="/" element={<LoginType />} />
                    <Route path="/signin" element={<Login />} />
                    <Route path="/signup" element={<Register />} />
                    <Route path="/home" element={<Newbooking />} />
                    <Route path="/mybookings" element={<Newmybookings />} />
                    <Route path="/profile" element={<Newprofile />} />
                    <Route path="/privacy-policy" element={<Privacypolicy />} />
                    <Route path="/contact-us" element={<Contactus />} />
                    <Route path="/address" element={<Address />} />
                    <Route path="/usersupport" element={<Usersupport />} />
                    <Route path="/notification" element={<Usermessage />} />
                    
                    {/* <Route path="/contact" element={<Contact />} /> */}
                    {/* <Route path="/newbooking" element={<Newbooking />} /> */}
                    {/* {<Route path="/mybookings" element={<Mybooking />} />} */}
                    {/* <Route path="/profile" element={<Profile />} /> */}


                    {/*============================ Driver panel========================= */}

                    <Route path="/ridersignup" element={<RiderRegister />} />
                    <Route path="/rider" element={<Riderlogin />} />
                    <Route path="/riderhome" element={<RiderHome />} />
                    <Route path="/riderprofile" element={<Driverprofile />} />
                    <Route path="/editriderdata" element={<Driveredit />} />
                    <Route path="/rideraddress" element={<RiderAddress />} />
                    <Route path="/shiftavailablity" element={<DriverShiftAvailablity />} />
                    <Route path="/ridersupport" element={<Ridersupport />} />
                    <Route path="/ridernotification" element={<Ridermessage />} />

                    {/* admin use */}

                    <Route path="/tnglogin" element={<TngLogin />} />

                </Routes>
            </Router>


        </>
    )

}

export default App;