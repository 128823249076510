import React, { useEffect, useState } from 'react'
import { Card, CardContent, CircularProgress, Fab, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Apiurl } from '../api_urls'
import Gmodal from './Gmodal'
import { Delete } from '@mui/icons-material'

const GAddressTable = ({ Address, usertype }) => {

    const user = useSelector((state) => state?.data?.userdata?.user)
    const rideruser = useSelector((state) => state?.data?.riderdata?.rider)
    const [alladdress, setalladdress] = useState([])
    const [isloading, setisloading] = useState(false)
    const [mymodaldata, setmymodaldata] = useState({
        modalshow: false,
        modaltype: "success",
        modalmessage: "raju"
    })

    function closemodal(e) {
        mymodaldata["modalshow"] = e
        mymodaldata["modalmessage"] = ""
        mymodaldata["modaltype"] = ""
        setmymodaldata({ ...mymodaldata })
    }

    async function getaddress() {
        setisloading(true)
        await axios.get(usertype == "user" ? `${Apiurl.GET_USER_ADDRESSES_NEW_URL}${user.id}/all` : `${Apiurl.GET_PARTICULAR_DRIVER_ADDRESSES_URL}${rideruser.id}`).then((res) => {
            if (res.data.status === 200) {
                let newdata = res.data.addresses
                setalladdress([...newdata])
                setisloading(false)
            }
        }).catch((err) => {
            mymodaldata["modalshow"] = true;
            mymodaldata["modaltype"] = "error";
            mymodaldata["modalmessage"] = "Something Went Wrong in Pickup Addresses fetch"
            setmymodaldata({ ...mymodaldata })
            setisloading(false)
        })
    }


    const deleteaddress = async (id) => {

        if (window.confirm(`Are you really want to delete the address`)) {
            let obj = {
                "user_id": usertype == "user" ? user?.id : rideruser?.id,
                "address_id": id,
                "tb": Address
            }

            await axios.post(Apiurl.DELETE_ADDRESS_URL, obj).then(async (response) => {
                if (response.data.status === 200) {
                    mymodaldata["modalshow"] = true;
                    mymodaldata["modaltype"] = "success";
                    mymodaldata["modalmessage"] = "Address Successfully Deleted"
                    setmymodaldata({ ...mymodaldata })
                    await getaddress()
                } else {
                    mymodaldata["modalshow"] = true;
                    mymodaldata["modaltype"] = "error";
                    mymodaldata["modalmessage"] = "Something went wrong in delete address"
                    setmymodaldata({ ...mymodaldata })
                }
            }).catch((err)=>{
                console.log(err)
            })
        }


    }

    useEffect(() => {
        getaddress()
    }, [])

    return (<>
        {mymodaldata.modalshow == true && <Gmodal closemodal={closemodal} handledata={mymodaldata} />}
        <Grid container spacing={5}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Typography variant='h4' sx={{ pt: 6 }}>
                    Your Addresses Details
                </Typography>
            </Grid>
            <Grid item xs={8} sx={{ m: "auto", textAlign: "center" }}>
                <Card>
                    <CardContent>
                        {isloading ? <CircularProgress />
                            :
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='center'>Sr No.</TableCell>
                                            <TableCell align='center'>Addresses</TableCell>
                                            <TableCell align='center'>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {alladdress.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell align='center'>{index + 1}</TableCell>
                                                <TableCell align='center'>{row?.address}</TableCell>
                                                <TableCell align='center'>
                                                    <Fab size='small' color='error' onClick={() => deleteaddress(row?.id)}><Delete /></Fab>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        }

                    </CardContent>
                </Card>
            </Grid>
        </Grid>

    </>
    )
}

export default GAddressTable