import { createSlice } from "@reduxjs/toolkit";

const RiderSlice = createSlice({
    name: "RiderSlice",
    initialState: {
        rider: null,
        editriderdetails: null,
    },
    reducers: {
        riderlogin(state, action) {
            state.rider = action.payload;
        },
        editriderdata(state, action) {
            state.editriderdetails = action.payload;
        }


    }
})

export default RiderSlice.reducer
export const { riderlogin, editriderdata } = RiderSlice.actions
