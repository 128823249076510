import { CCollapse } from "@coreui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import lof from "../Components/img/logo-main.png"
import { useDispatch, useSelector } from "react-redux";
import { riderlogin } from "../redux_store/RiderloginSlice";
import Notificationcounter from "../Components/Notificationcounter";

function Driverheader() {

    const navi = useNavigate();
    const [show, setshow] = useState(false)
    const driver = useSelector((state) => state?.data?.riderdata?.rider)
    const dispatch = useDispatch()


    function logout() {
        if (window.confirm("Are You Really Want To Logout Your Account ?")) {
            localStorage.removeItem("driver")
            localStorage.removeItem("drivername")
            localStorage.removeItem("persist:root")
            dispatch(riderlogin(null))
            navi("/rider")
            window.location.reload()
        }
    }





    useEffect(() => {
        if (!driver) {
            navi("/rider")
        }
    }, [])

    return (
        <>

            {/* <div className="mininav">
                <div className="container">
                    <div className="list1">
                        <div className="inncon"><i className="bx bx-envelope"></i></div>
                        <div className="inn"><a href="mailto: info@pickanddrop.app">info@pickanddrop.app</a></div>
                        <div className="inncon"><i className=" bx bx-phone"></i></div>
                        <div className="inn"><a href="tel:+14372284743">+14372284743</a></div>
                        <div className="inncon"><i className=" bx bx-phone"></i></div>
                        <div className="inn"><a href="tel:+919216086981">+919216086981</a></div>

                    </div>

                </div>

            </div> */}
            {driver && (<>

                <div className="navbar1">
                    <div className="container">
                        <div className="logopic" onClick={() => navi("/riderhome")} style={{ cursor: "pointer" }}>
                            <img src={lof} className="img-fluid" />
                        </div>

                        <div className="mylist">

                            <div >
                                <a className="inn " onClick={() => navi("/riderhome")}>Booking Assigned</a>
                            </div>

                            <div>
                                <a className="inn" onClick={() => navi("/riderprofile")}>MyProfile</a>
                            </div>

                            <div className="inn" onClick={() => navi("/rideraddress")}>Saved Address</div>


                            <div className="inn" onClick={() => navi("/shiftavailablity")}>Availability Schedule</div>

                            <div className="inn" onClick={() => navi("/ridersupport")}>Support</div>

                            <div className="inn" onClick={logout}>Logout</div>

                            <div className="inncon">
                                <i onClick={() => setshow(!show)} className={show ? "bx bx-x" : "bx bx-menu"} ></i>
                            </div>

                        </div>

                    </div>

                    <CCollapse visible={show} >
                        <div className="navdrop">
                            <ul className="list-group">
                                <li className="list-group-item"><a onClick={() => navi("/riderhome")}>Booking Assigned</a></li>
                                <li className="list-group-item"><a onClick={() => navi("/riderprofile")}>MyProfile</a></li>
                                <li className="list-group-item" onClick={() => navi("/rideraddress")}><a>Saved Address</a></li>
                                <li className="list-group-item" onClick={() => navi("/shiftavailablity")}><a>Availability Schedule</a></li>
                                <li className="list-group-item" onClick={() => navi("/ridersupport")}><a>Support</a></li>
                                <li className="list-group-item" onClick={logout}><a>Logout</a></li>
                            </ul>
                        </div>
                    </CCollapse>
                </div>
            </>)}

            {driver && <Notificationcounter usertype={"driver"} email={driver?.email} route={"/ridernotification"} />}

        </>
    )
}


export default Driverheader;