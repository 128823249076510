import { createSlice } from "@reduxjs/toolkit";


const LoginSlice = createSlice({
    name: "loginslice",
    initialState: {
        user: null
    },
    reducers: {
        loginuser(state, action) {
            state.user = action.payload
        }
    }
})

export default LoginSlice.reducer
export const {loginuser} = LoginSlice.actions