import React, { useEffect, useState } from "react";
import { auth } from "../Firebase";
import firebase from "firebase";
import { useNavigate } from "react-router-dom";
import Myheader from "./Myheader";
import axios from "axios"
import { country, manager } from "../Allapis";
import GooglePlaces from "./GooglePlaces";
import { Apiurl } from "../api_urls";
import { loginuser } from "../redux_store/LoginSlice";
import { useDispatch } from "react-redux";
import { GToaster } from "./g_toaster";
import { ToastContainer } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
const toster = new GToaster()
function Register() {
	const [btnloader, setbtnloader] = useState(false)
	const objdata = {
		"fullname": "",
		"address": "",
		"country_code": "",
		"contact": "",
		"email": "",
		"distributor": "",
	}

	const navi = useNavigate();
	const dispatch = useDispatch();
	const [owner, setowner] = useState([]);
	const [code, setcode] = useState(country);
	const [otpdata, setotpdata] = useState(null);
	const [obj, setobj] = useState({ ...objdata });
	const regurl = Apiurl.USER_REGISTERATION_URL;

	async function Managedby() {
		await manager.then((succ) => {
			setowner(succ)
		}).catch((err) => {
			console.log(err);
		})
	}

	async function registeruser(data) {
		await axios.post(regurl, data).then((response) => {
			if (response.status === 200) {
				if (response.data.status === 409) {
					toster.warning({ title: response.data.msg })
					setotpdata(null)
					setbtnloader(false)
				} else if (response.data.status === 200) {
					setbtnloader(false)
					toster.success({ title: "Registered Successfully" })
					dispatch(loginuser(response.data.data))
					setobj({ ...objdata })
					setotpdata(null)
					localStorage.removeItem("user")
					localStorage.removeItem("username")
					localStorage.removeItem("usercontact")
					navi("/mybookings");
					window.location.reload()

				} else {
					toster.error({ title: "Something went wrong in registeration" })
					setotpdata(null)
					setbtnloader(false)
				}
			}
			else {
				toster.error({ title: "Something went wrong in registeration" })
				setotpdata(null)
				setbtnloader(false)
			}
		}).catch((err) => {
			toster.error({ title: "Something went wrong in registeration" })
			setbtnloader(false)
		})
	}

	function handlechange(key, value) {
		setobj({ ...obj, [key]: value })
	}

	function gopicadress(e, zip, city, label,lat,long) {
		let mydata = JSON.parse(JSON.stringify(obj))
		let myobj = {
			['id']: 0, ["address"]: label, ["tag"]: "Home", ["postal_code"]: zip, ["city"]: city, ['type']: "pickup",
			["lat"]: lat,
            ["long"]: long,
		}
		mydata["address"] = myobj
		setobj({ ...mydata })
	}

	function Regdata(e) {
		e.preventDefault();
		setbtnloader(true)
		localStorage.removeItem("persist:root")
		const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
			'size': 'invisible'
		});
		const phoneNumber = `${obj.country_code}${obj.contact}`;
	
		auth.signInWithPhoneNumber(phoneNumber, appVerifier)
			.then((confirmationResult) => {
				setotpdata(confirmationResult);
				setbtnloader(false)
				toster.success({ title: "Otp is send to your registered mobile number please verify" })
			}).catch((error) => {
				toster.error({ title: "something went wrong please reload your page again" })
			})
	}

	function resend() {
		const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
			'size': 'invisible'
		});
		const phoneNumber = `${obj.country_code}${obj.contact}`;
		auth.signInWithPhoneNumber(phoneNumber, appVerifier)
			.then((confirmationResult) => {
				setotpdata(confirmationResult);
				toster.success({ title: "Otp is send to your registered mobile number please verify" })
			}).catch((error) => {
				toster.error({ title: "something went wrong please reload your page again" })
			})
	}

	function verify(e) {
		e.preventDefault();
		setbtnloader(true)
		let data = new FormData(e.currentTarget);
		let v = data.get("Verify");
		otpdata.confirm(v).then((succ) => {
			registeruser(obj)
		}).catch((err) => {
			toster.error({ title: "something went wrong please reload your page again" })
		})
	}

	useEffect(() => {
		Managedby();
	}, [])


	return (
		<>
			{/* <Myheader /> */}
			<div className="page-title-area item-bg-1 jarallax" data-jarallax='{"speed": 0.3}'>
				<div className="container">
					<div className="page-title-content">
						<div className="row align-items-center">
							<div className="col-lg-6 col-sm-6">
								<h2>Sign Up</h2>
							</div>
						</div>
					</div>
				</div>
			</div>

			{otpdata ?
				<section className="sign-up-area ptb-100">
					<div className="container">
						<div className="row d-flex justify-content-center">
							<div className="col-lg-6 col-md-8 col-sm-12 col-xs-12  ">
								<div className="contact-form-action">
									<div className="form-heading text-center">
										<h3 className="form-title">Verify Your Otp</h3>
										<p className="reset-desc">Enter  your six-digit otp that will receive on your  Contact No. If you have any issue about otp receive then contact us.</p>
									</div>
									<form method="post" onSubmit={verify}>
										<div className="row ">
											<div className="col-12">
												<div className="form-group">
													<input required className="form-control" type="text" name="Verify" placeholder="Enter Your Otp" />
												</div>
											</div>

											<div className="col-12">
												<div className="row">
													<div className="col-6 d-flex justify-content-start">
														<button type="button" className="btn btn-outline-primary mt-4" onClick={resend}>
															Resend Otp
														</button>
													</div>

													<div className="col-6 d-flex justify-content-end">
														{btnloader ? <CircularProgress /> :
															<button className="default-btn btn-two mt-4 m-0" type="submit">
																Verify Otp
															</button>
														}
													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</section>
				:
				<section className="sign-up-area ptb-100">
					<div className="container">
						<div className="row d-flex justify-content-center">
							<div className="col-lg-8 ">
								<div className="contact-form-action">
									<div className="form-heading text-center">
										<h3 className="form-title">Create an account!</h3>
										<p className="form-desc">with your social network.</p>
									</div>
									<form method="post" onSubmit={Regdata}>
										<div className="row">

											<div className="col-lg-6 col-sm-6 mb-2">
												<label className="form-label">Name</label>
												<input required className="form-control" type="text" value={obj?.fullname} name="fullname" onChange={(e) => handlechange(e.target.name, e.target.value)} />
											</div>

											<div className="col-lg-6 col-sm-6 mb-2">
												<label className="form-label">Email</label>
												<input required className="form-control" type="email" value={obj?.email} name="email" onChange={(e) => handlechange(e.target.name, e.target.value)} />

											</div>

											<div className="col-lg-2 col-sm-2 col-4 mb-2">
												<label className="form-label">Country </label>
												<select className="form-select form-control form-select-lg" value={obj?.country_code} required name="country_code" onChange={(e) => handlechange(e.target.name, e.target.value)} >
													<option defaultValue={""}  ></option>
													{code.map((row) => (
														<React.Fragment key={row.name}>
															<option value={row.dial_code}>{row.dial_code}  {row.name}</option>
														</React.Fragment>
													))}
												</select>
											</div>

											<div className="col-lg-4 col-sm-4 col-8 mb-2">
												<label className="form-label">Phone No.</label>
												<input required className="form-control" type="tel" name="contact" maxLength={"10"} value={obj?.contact} onChange={(e) => handlechange(e.target.name, e.target.value)} />
											</div>

											<div className="col-lg-6 col-sm-6 mb-2">
												<label className="form-label">Address</label>
												<GooglePlaces gopicadress={gopicadress} label={"gopicadress"} />
											</div>


											<div className="col-md-12 col-sm-12 mb-2" >
												<label className="form-label">Service Provider</label>
												<select className="form-select form-control" required name="distributor" value={obj?.distributor} onChange={(e) => handlechange(e.target.name, e.target.value)} >
													<option defaultValue={" "} className="form-control" ></option>
													{owner.map((row) => (
														<React.Fragment key={row.id}>
															<option value={row.id}>{row.name}</option>
														</React.Fragment>
													))}
												</select>
											</div>

											<div className="col-12 mt-3">
												{btnloader ? <CircularProgress /> :
													<button className="default-btn btn-two" type="submit">
														Register Account
													</button>
												}
											</div>

											<div className="col-12">
												<p className="account-desc">
													Already have an account?
													<a style={{ cursor: "pointer" }} onClick={() => navi("/signin")} > Login</a>
												</p>
											</div>

											<div style={{ display: "none" }} id="recaptcha-container"></div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</section>
			}

		</>
	)
}

export default Register;