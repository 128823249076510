import { Cancel } from '@mui/icons-material';
import { Checkbox, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

const Mymutiselect = ({ options, disabled, value, handlechange, allchange, selectall }) => {
    const [show, setshow] = useState(false);
    const [newoption, setnewoption] = useState([]);
    function myhandlechange(key, value, index, ele) {
        let jsondata = JSON.parse(JSON.stringify(newoption))
        jsondata[index][key] = value
        setnewoption([...jsondata])
        handlechange(jsondata)
    }

    function handleselect(e) {
        allchange(e)
        let jsondata = JSON.parse(JSON.stringify(newoption))
        let changedata = jsondata.map((ele, index) => {
            if (!!ele?.msg) {
                return { ...ele }
            } else {
                return { ...ele, ["checkdata"]: e }
            }
        })
        handlechange(changedata)
        setnewoption([...changedata])
    }



    useEffect(() => {
        setnewoption(options)
    }, [options])

    return (
        <>
            <input className='form-control' required value={value?.length > 0 ? `${value.filter((ele) => ele?.checkdata).map((ele) => ele?.label).join(",")}...` : value?.length == newoption.length ? "All Dates Selected" : ""} style={{ overflowX: "scroll" }} readOnly onClick={() => setshow(!show)} disabled={disabled} />
            {show &&
                <ul className='mydatelist' >
                    <li style={{ padding: "7px 0px", borderBottom: "1px solid lightgray" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div><Checkbox checked={selectall} onChange={() => handleselect(!selectall)} size='medium' /></div>
                                <div style={{ color: "#117fb7", paddingLeft: "8px" }} >Select All</div>
                            </div>
                            <button className='btn btn-danger'  onClick={() => setshow(false)}>
                               Done
                            </button>
                        </div>
                    </li>
                    {newoption.map((ele, index) => (
                        <li style={{ padding: "7px 0px", borderBottom: "1px solid lightgray", pointerEvents: !!ele?.msg && "none" }}>
                            <div>
                                <div style={{ display: "flex", cursor: !!ele?.msg && "no-drop", background: !!ele?.msg && "#EBEBE4" }}>
                                    <div><Checkbox disabled={!!ele?.msg} checked={ele?.checkdata} onChange={(e) => myhandlechange("checkdata", !ele?.checkdata, index, ele)} size='medium' /></div>
                                    <div style={{ marginTop: "4px", paddingLeft: "8px" }}>
                                        <div >{ele?.label}</div>
                                        {!!ele?.msg && <div style={{ color: "red" }}>{ele?.msg}</div>}
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                     <li style={{ padding: "7px 0px", borderBottom: "1px solid lightgray" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                               
                            </div>
                            <button className='btn btn-danger'  onClick={() => setshow(false)}>
                               Done
                            </button>
                        </div>
                    </li>

                </ul>}

        </>
    )
}

export default Mymutiselect