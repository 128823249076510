import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { encryptTransform } from 'redux-persist-transform-encrypt'
import { combineReducers } from '@reduxjs/toolkit'
import LoginSlice from './LoginSlice'
import BookingDataSlice from './BookingDataSlice'
import RiderloginSlice from './RiderloginSlice'
import BookingdateSlice from './BookingdateSlice'
import Shiftavailabilty from './Shiftavailabilty'



const persistConfig = {
    key: 'root',
    storage,
    whiteList: ["userdata","userbookings","riderdata","alldatesdata","ridershiftdata"],
    // transforms: [encryptor]
    transforms: [
        encryptTransform({
            secretKey: 'pick&dropapp'
        })
    ]
}

const rootReducer = combineReducers({
userdata:LoginSlice,
userbookings:BookingDataSlice,
riderdata : RiderloginSlice,
alldatesdata:BookingdateSlice,
ridershiftdata:Shiftavailabilty
})


const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = configureStore({
    reducer: {
        data: persistedReducer
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        })
})

const persistedStore = persistStore(store)

export { store, persistedStore }