import React from 'react'
import Support from './Support'

const Usersupport = () => {
    return (
        <>
            <Support usertype={"user"} />
        </>
    )
}

export default Usersupport