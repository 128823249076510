import { createSlice } from "@reduxjs/toolkit";

const DatesSlice = createSlice({
    name: "datesSlice",
    initialState: {
        alldates: []
    },
    reducers: {
        addalldates(state, action) {
            state.alldates = action.payload
        }
    }
})

export default DatesSlice.reducer
export const { addalldates } = DatesSlice.actions
