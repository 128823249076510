import axios from "axios";
import { Apiurl } from "./api_urls";

const manager = axios.get(Apiurl.GET_BOOKING_AGENCIES_URL).then((succ) => {
    if (succ.status == 200) {
        return succ.data.data
    } else {
        return []
    }
}).catch((err) => {
    console.log(err)
    return []
})


const country = [
    {
        "name": "Canada",
        "dial_code": "+1",
        "code": "CA"
    },
    {
        "name": "India",
        "dial_code": "+91",
        "code": "IN"
    },
]


export { country, manager };