import React, { useEffect, useState } from 'react'
import { Apiurl } from '../api_urls'
import axios from 'axios'
import { Badge, Fab } from '@mui/material'
import { NotificationsActive } from '@material-ui/icons'
import { useNavigate } from 'react-router-dom'
import tune from "../Components/img/tune.mp3"
import { GToaster } from "./g_toaster";
const toster = new GToaster()

const Notificationcounter = ({ usertype, email, route }) => {
    const [counter, setcounter] = useState(0)
    let navi = useNavigate();
    const sound = new Audio(tune)
    const notificationcounter = async () => {
        if (!!usertype) {
            await axios.get(`${Apiurl.GET_NOTIFICATION_COUNTER_URL}/${usertype}/${email}`).then((response) => {
                if (response.status === 200) {
                    setcounter(response.data?.total)
                    if (response.data?.total > 0) {
                        sound.play()
                        toster.success({ title: "New Notification" })
                    }
                } else {
                    console.log("something went wrong in notifification")
                }
            })
        }
    }


    useEffect(() => {
        notificationcounter()
    }, [])

    return (<>
        <Badge badgeContent={counter} color="secondary" sx={{ position: "fixed", right: 40, bottom: 40, zIndex: 2 }}>
            <Fab color="primary" onClick={() => navi(route)}><NotificationsActive /></Fab>
        </Badge>
    </>)
}

export default Notificationcounter