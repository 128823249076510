
import firebase from "firebase";

// const firebaseConfig = {
//   apiKey: "AIzaSyAsT_dNVxSfNpejmB7vc7Fi36muV71TwDI",
//   authDomain: "shuttle-service-2fd87.firebaseapp.com",
//   projectId: "shuttle-service-2fd87",
//   storageBucket: "shuttle-service-2fd87.appspot.com",
//   messagingSenderId: "890203096209",
//   appId: "1:890203096209:web:44ae13204aa3789ef02b30"
// };


// const firebaseConfig = {
//   apiKey: "AIzaSyBZU3K3LjZ9IUC-oQBtLwMeNT12Z9usojQ",
//   authDomain: "shuttle-service-11e11.firebaseapp.com",
//   projectId: "shuttle-service-11e11",
//   storageBucket: "shuttle-service-11e11.appspot.com",
//   messagingSenderId: "761378746659",
//   appId: "1:761378746659:web:974d7f9623fd7ae06edc96"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBZU3K3LjZ9IUC-oQBtLwMeNT12Z9usojQ",
  authDomain: "shuttle-service-11e11.firebaseapp.com",
  projectId: "shuttle-service-11e11",
  storageBucket: "shuttle-service-11e11.appspot.com",
  messagingSenderId: "761378746659",
  appId: "1:761378746659:web:974d7f9623fd7ae06edc96"
};


  firebase.initializeApp(firebaseConfig);

  const db = firebase.firestore();

  const auth = firebase.auth();
  const  store = firebase.storage();

  export {db,auth,store}