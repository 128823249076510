import React, { useEffect, useState } from "react";
import Myheader from "./Myheader";
import { Card, CardContent, CircularProgress, Divider, Fab, Grid, Typography } from "@mui/material";
import logo from "../Components/img/cwok_casual_2.png"
import { Mail, Phone } from "@material-ui/icons";
import axios from "axios";
import { Apiurl } from "../api_urls";
import Gmodal from "./Gmodal";
import Driverheader from "../DriverComponent/Driverheader";

function Support({ usertype }) {
	const [suppordata, setsupportdata] = useState(null);
	const [isloading, setisloading] = useState(false);
	const [mymodaldata, setmymodaldata] = useState({
		modalshow: false,
		modaltype: "success",
		modalmessage: "raju"
	})

	function closemodal(e) {
		mymodaldata["modalshow"] = e
		mymodaldata["modalmessage"] = ""
		mymodaldata["modaltype"] = ""
		setmymodaldata({ ...mymodaldata })
	}

	const getsupportdetails = async () => {
		setisloading(true)
		await axios.get(Apiurl.GET_SUPPORT_DETAILS_URL).then((response) => {
			if (response.status === 200) {
				if (response.data.status === 200) {
					setsupportdata(response.data.data)
					setisloading(false)
				}
			} else {
				mymodaldata["modalshow"] = true;
				mymodaldata["modaltype"] = "error";
				mymodaldata["modalmessage"] = `Server Error`
				setmymodaldata({ ...mymodaldata })
				setisloading(false)
			}
		})
	}

	useEffect(() => {
		getsupportdetails()
	}, [])




	return (
		<>
			{usertype === "driver" ? <Driverheader /> : <Myheader />}

			{mymodaldata.modalshow == true && <Gmodal closemodal={closemodal} handledata={mymodaldata} />}

			<Grid container spacing={3} sx={{ p: 2 }}>
				<Grid item md={6} sm={8} xs={12}>
					<Card sx={{ boxShadow: "1px 1px 7px 1px grey" }}>
						<CardContent>
							{isloading ? <center><CircularProgress /></center> :
								<Grid container >
									<Grid item xs={12} sx={{ background: "#117fb7", color: "white", borderRadius: "10px" }}>
										<Typography variant="h4" sx={{ py: 1, pl: 2, color: "white", fontSize: { xs: "23px", sm: "30px" } }}>For Support Any Query : </Typography>
									</Grid>

									{suppordata && <>
										<Grid item xs={12} >
											<hr />
										</Grid>
										<Grid item xs={12} sx={{ borderLeft: "10px solid #117fb7", pl: 3, borderRadius: 2, mb: 2 }}>
											<Grid container>
												<Grid item xs={12}>
													<Typography variant="h5" sx={{ py: 2 }}>{suppordata?.block1} : </Typography>
												</Grid>

												<Grid item xs={12}>
													<Typography variant="body1" sx={{ py: 1.5 }}><a href="" style={{ fontSize: "18px" }}>{suppordata?.block2}</a> </Typography>
												</Grid>

												<Grid item xs={12}>
													<Typography variant="body1" sx={{ py: 1.5 }}><a href="" style={{ fontSize: "18px" }}>{suppordata?.block3}</a></Typography>
												</Grid>


												<Grid item xs={12}>
													<Typography variant="h5" sx={{ py: 2 }}>{suppordata?.block4} : </Typography>
												</Grid>

												<Grid item xs={12}>
													<Typography variant="body1" sx={{ py: 1.5 }}><a href="" style={{ fontSize: "18px" }}>{suppordata?.block5} </a></Typography>
												</Grid>
											</Grid>

										</Grid>

										<Grid item xs={12} >
											<hr />
										</Grid>

										<Grid item xs={12}>
											<Typography variant="h5" sx={{ py: 1 }}>Contact Details : </Typography>
										</Grid>

										<Grid item xs={12} sx={{ display: "flex", alignItems: "center", pt: 3 }}>
											<Fab size="small" color="primary" sx={{ mr: 2 }}><Mail /></Fab>
											<Typography variant="body1" ><a href={`mailto: ${suppordata?.block10}`} style={{ fontSize: "20px" }}>{suppordata?.block10} </a></Typography>
										</Grid>

										<Grid item xs={12} sx={{ display: "flex", alignItems: "center", pt: 4 }}>
											<Fab size="small" color="primary" sx={{ mr: 2 }}><Phone /></Fab>
											<Typography variant="body1"><a href={`tel:${suppordata?.block7}`} style={{ fontSize: "20px" }}>{suppordata?.block7}</a> ({suppordata?.block6})</Typography>
										</Grid>

										<Grid item xs={12} sx={{ display: "flex", alignItems: "center", pt: 4 }}>
											<Fab size="small" color="primary" sx={{ mr: 2 }}><Phone /></Fab>
											<Typography variant="body1" ><a href={`tel:${suppordata?.block9}`} style={{ fontSize: "20px" }}>{suppordata?.block9}</a> ({suppordata?.block8})</Typography>
										</Grid>

									</>}

								</Grid>

							}

						</CardContent>
					</Card>
				</Grid>
				<Grid item md={6} sx={{ display: { xs: "none", sm: "none", md: "block" } }} >
					<img src={logo} style={{ height: "750px", width: "100%" }} />
				</Grid>
			</Grid>


		</>

	)

}

export default Support;