import React from 'react'
import Support from '../Components/Support'

const Ridersupport = () => {
    return (
        <>
            <Support usertype={"driver"}/>
        </>
    )
}

export default Ridersupport