import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment-timezone';

export class GToaster {
  success({ title, position }) {
    let objsuccess = {
      position: position ?? 'top-right',
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored'
    }

    return toast.success(title, objsuccess)
  }

  warning({ title, position }) {
    let objWarning = {
      position: position ?? 'top-right',
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored'
    }

    return toast.warning(title, objWarning)
  }

  error({ title, position }) {
    let objerror = {
      position: position ?? 'top-right',
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored'
    }

    return toast.error(title, objerror)
  }
}


export function customDateFormat(date, format) {
  return moment(`${new Date(date)}`).format(`${format}`);
}

export function customshiftvalue() {
  let currentHour = moment().format("HH");
  if (currentHour >= 3 && currentHour < 12) {
    return "morning";
  } else if (currentHour >= 12 && currentHour < 15) {
    return "afternoon";
  } else if (currentHour >= 15 && currentHour < 20) {
    return "evening";
  } else if (currentHour >= 20 || currentHour < 3) {
    return "night";
  } else {
    return "Hello"
  }
}

export function getcustomtime() {
  let time = moment().format('LT');
  return time;
}

export function getcustomdate(){
  let date = moment().format('YYYY-MM-DD');
  return date;
}

export function getcustomformatdate(date){
  let reversedate = `${date}`.split("-").reverse().join("-")
  return reversedate
}

