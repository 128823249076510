import React, { useEffect, useState } from 'react'
import Myheader from './Myheader'
import { Button, Card, CardContent, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import axios from 'axios'
import { Apiurl } from '../api_urls'
import { useDispatch, useSelector } from 'react-redux'
import { country, manager } from '../Allapis'
import GooglePlaces from './GooglePlaces'
import { Add } from '@mui/icons-material'
import { GToaster } from './g_toaster'
import { auth } from '../Firebase'
import firebase from 'firebase'
import { ToastContainer } from 'react-toastify'
import { loginuser } from '../redux_store/LoginSlice'
import Gmodal from './Gmodal'

const toster = new GToaster()
const Newprofile = () => {
    const user = useSelector((state) => state.data.userdata.user.id);
    const dispatch = useDispatch()
    const [btnloader, setbtnloader] = useState(false)
    const [profiledata, setprofiledata] = useState([]);
    const [owner, setowner] = useState([]);
    const [editaddress, seteditaddress] = useState(false);
    const [country_code, setcountry_code] = useState(country);
    const [pickupaddress, setpickupaddress] = useState([])
    const [otpdata, setotpdata] = useState(null)
    const [oldno, setoldno] = useState("");
    const user_profile_update = Apiurl.UPDATE_USER_PROFILE_URL;

    const [mymodaldata, setmymodaldata] = useState({
        modalshow: false,
        modaltype: "success",
        modalmessage: "raju"
    })

    const objdata = {
        "user_id": user,
        "fullname": "",
        "address": "",
        "country_code": "",
        "contact": "",
        "email": "",
        "distributor": "",
        "routine": "regular"
    }



    const [obj, setobj] = useState({ ...objdata })


    function Regdata() {
        var ar = []
        axios.get(`${Apiurl.GET_PARTICULAR_USER_DATA_URL}${user}`).then((succ) => {
            ar.push(succ.data.data)
            setprofiledata([...ar])
        }).catch((err) => {
            mymodaldata["modalshow"] = true;
            mymodaldata["modaltype"] = "error";
            mymodaldata["modalmessage"] = "Server Error"
            setmymodaldata({ ...mymodaldata })
        })
    }

    async function Managedby() {
        await manager.then((succ) => {
            setowner(succ)
        }).catch((err) => {
            mymodaldata["modalshow"] = true;
            mymodaldata["modaltype"] = "error";
            mymodaldata["modalmessage"] = "Something Went Wrong in Service provider fetch"
            setmymodaldata({ ...mymodaldata })
        })
    }


    async function getpickupaddress() {
        await axios.get(`${Apiurl.GET_USER_ADDRESSES_NEW_URL}${user}/${"all"}`).then((res) => {
            if (res.data.status === 200) {
                setpickupaddress(res.data.addresses)
            }
        }).catch((err) => {
            mymodaldata["modalshow"] = true;
            mymodaldata["modaltype"] = "error";
            mymodaldata["modalmessage"] = "Something Went Wrong in Pickup Addresses fetch"
            setmymodaldata({ ...mymodaldata })
        })
    }

    function gopicadress(e, zip, city, label,lat,long) {
        let mydata = JSON.parse(JSON.stringify(obj))
        let myobj = {
            ['id']: 0, ["address"]: label, ["tag"]: "Home", ["postal_code"]: zip, ["city"]: city, ['type']: "pickup",
            ["lat"]: lat,
            ["long"]: long
        }
        mydata["address"] = myobj
        setobj({ ...mydata })
    }

    function handlechange(key, val) {
        setobj({ ...obj, [key]: val });
    }

    function edit(e) {
        setobj({ ...e, "user_id": user })
        setoldno(e?.contact)
        localStorage.removeItem("user")
        localStorage.removeItem("username")
        localStorage.removeItem("usercontact")
        // window.location.reload()
    }


    async function updateprofile(data) {

        await axios.post(user_profile_update, data).then((response) => {
            if (response.status === 200) {
                if (response.data.status === 400) {
                    toster.warning({ title: response.data.msg })
                    setotpdata(null)
                    setbtnloader(false)
                } else if (response.data.status === 200) {
                    mymodaldata["modalshow"] = true;
                    mymodaldata["modaltype"] = "success";
                    mymodaldata["modalmessage"] = "Profile Successfully Updated"
                    setmymodaldata({ ...mymodaldata })
                    setobj({ ...objdata })
                    seteditaddress(false)
                    setbtnloader(false)
                    dispatch(loginuser(response.data.data))
                    setotpdata(null)
                    Regdata()
                } else {
                    toster.error({ title: "Something Went Wrong in profile Update" })
                    setotpdata(null)
                    setbtnloader(false)
                }
            }
            else {
                toster.error({ title: "Something Went Wrong in profile Update" })
                setotpdata(null)
                setbtnloader(false)
            }
        }).catch((err) => {
            toster.error({ title: "Something Went Wrong in profile Update" })
            setbtnloader(false)
        })
    }


    function editdata(e) {
        e.preventDefault();
        setbtnloader(true)
        if (oldno === obj.contact) {
            updateprofile(obj)
        } else {
            const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible'
            });
            const phoneNumber = `${obj.country_code}${obj.contact}`;
            auth.signInWithPhoneNumber(phoneNumber, appVerifier)
                .then((confirmationResult) => {
                    setbtnloader(false)
                    setotpdata(confirmationResult);
                }).catch((error) => {
                    toster.error({ title: "something went wrong please reload your page again" })
                })
        }



    }


    function resend() {
        const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible'
        });
        const phoneNumber = `${obj.country_code}${obj.contact}`;
        auth.signInWithPhoneNumber(phoneNumber, appVerifier)
            .then((confirmationResult) => {
                setotpdata(confirmationResult);
            }).catch((error) => {
                toster.error({ title: "something went wrong please reload your page again" })
            })
    }

    function verify(e) {
        e.preventDefault();
        setbtnloader(true)
        var data = new FormData(e.currentTarget)
        let Otp = data.get("ver")
        otpdata?.confirm(Otp).then((succ) => {
            updateprofile(obj)
        }).catch((err) => {
            toster.error({ title: "something went wrong please reload your page again" })
        })
    }

    function closemodal(e) {
        mymodaldata["modalshow"] = e
        mymodaldata["modalmessage"] = ""
        mymodaldata["modaltype"] = ""
        setmymodaldata({ ...mymodaldata })
    }



    useEffect(() => {
        Regdata()
        Managedby()
        getpickupaddress()
    }, [])



    return (
        <>
            <Myheader />
            {mymodaldata.modalshow == true && <Gmodal closemodal={closemodal} handledata={mymodaldata} />}

            <Grid container spacing={6}>
                <Grid sx={{ m: "auto" }} item xs={10}>
                    <Grid container spacing={6}>

                        <Grid item xs={6} sx={{ mt: 4 }}>
                            <CardContent>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h5" sx={{ py: 1 }}> Your Profile Details</Typography>
                                        {profiledata.map((row) => (<React.Fragment key={row?.id}>

                                            <div className="row">
                                                <div className="col-sm-3 col-xs-3 col-3">
                                                    <p className="mb-0">Name</p>
                                                </div>
                                                <div className="col-sm-9 col-xs-9 col-9">
                                                    <p className="text-muted mb-0">{row?.fullname}</p>
                                                </div>
                                            </div>
                                            <hr />

                                            <div className="row">
                                                <div className="col-sm-3 col-xs-3 col-3">
                                                    <p className="mb-0">Email</p>
                                                </div>
                                                <div className="col-sm-9 col-xs-9 col-9">
                                                    <p className="text-muted mb-0">{row?.email}</p>
                                                </div>
                                            </div>
                                            <hr />

                                            <div className="row">
                                                <div className="col-sm-3 col-xs-3 col-3">
                                                    <p className="mb-0">Phone</p>
                                                </div>
                                                <div className="col-sm-9 col-xs-9 col-9">
                                                    <p className="text-muted mb-0">{row?.country_code} {row?.contact}</p>
                                                </div>
                                            </div>
                                            <hr />

                                            <div className="row">
                                                <div className="col-sm-3 col-xs-3 col-3">
                                                    <p className="mb-0">Address</p>
                                                </div>
                                                <div className="col-sm-9 col-xs-9 col-9">
                                                    <p className="text-muted mb-0">{row?.address?.address}</p>
                                                </div>
                                            </div>
                                            <hr />

                                            <div className="row">
                                                <div className="col-sm-12 col-12" >
                                                    <button className="default-btn btn-two" style={{ background: "white" }} onClick={() => edit(row)} >
                                                        Edit Details
                                                    </button>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        ))}
                                    </CardContent>
                                </Card>
                            </CardContent>

                        </Grid>


                        <Grid item xs={6} sx={{ mt: 4 }}>

                            {otpdata ?
                                <CardContent>
                                    <Card>
                                        <CardContent>
                                            <CardContent>
                                                <Typography align="center" variant="h4">Verify Your Otp</Typography>
                                                <p style={{ textAlign: "center", color: "grey" }}>Enter  your six-digit otp that will receive on your  Contact No. If you have any issue about otp receive then <a >contact us.</a></p>
                                            </CardContent>
                                            <form onSubmit={verify}>
                                                <CardContent>
                                                    <input type={"text"} className="form-control serinput" name="ver" />
                                                </CardContent>

                                                <CardContent>
                                                    {btnloader ? <CircularProgress /> : <Button size="large" type="submit" variant="contained" >Verify Otp</Button>}
                                                    <Button size="large" type="submit" variant="contained" color="secondary" sx={{ float: "right" }} onClick={resend}>Resend Otp</Button>
                                                </CardContent>
                                            </form>

                                            <div style={{ display: "none" }} id="recaptcha-container"></div>

                                        </CardContent>
                                    </Card>
                                </CardContent>
                                :
                                < CardContent >
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h5" sx={{ pb: 1 }}>Edit Your Details</Typography>
                                            <Typography variant="subtitle1" sx={{ pb: 1 }} color={"#117fb7"}>The information can be edited</Typography>
                                            <Divider />

                                            <form onSubmit={editdata}>
                                                <Grid container className="profileinput" spacing={3}>
                                                    <Grid item lg={6} md={6} sm={6} xs={12} >

                                                        <label className="form-label">Name</label>
                                                        <input required className="form-control" value={obj?.fullname} onChange={(e) => handlechange("fullname", e.target.value)} type="text" name="fullname" />


                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={12}   >
                                                        <label className="form-label">Email</label>
                                                        <input required className="form-control" type="email" name="email" value={obj?.email} onChange={(e) => handlechange("email", e.target.value)} />

                                                    </Grid>

                                                    <Grid item lg={6} md={6} sm={6} xs={12} >
                                                        <label className="form-label">{editaddress ? "Address" : "Select Address"}</label>
                                                        <span onClick={() => seteditaddress(!editaddress)} style={{ color: "#117fb7", paddingLeft: "18px", fontSize: "16px", cursor: "pointer", fontWeight: "bolder" }}><u><Add />{editaddress ? "Old" : "New"} Address</u></span>
                                                        {editaddress ? (<>
                                                            <GooglePlaces gopicadress={gopicadress} label={"gopicadress"} />
                                                        </>) : (<>
                                                            <select className="form-select form-control" required id="pickadress"
                                                                value={JSON.stringify(obj?.address)}
                                                                name="pickadress" onChange={(e) => handlechange("address", JSON.parse(e.target.value))}>
                                                                <option defaultValue={""} className="form-control" ></option>
                                                                {pickupaddress.map((row) => {
                                                                    return (<React.Fragment key={row?.id}>
                                                                        <option value={JSON.stringify(row)} >
                                                                            {row?.address}
                                                                        </option>
                                                                    </React.Fragment>)
                                                                })}
                                                            </select>
                                                        </>)}
                                                    </Grid>


                                                    <Grid item lg={2} md={2} sm={2} xs={4} >
                                                        <label className="form-label">Country</label>
                                                        <select className="form-select form-control form-select-lg" required name="country_code" value={obj?.country_code} onChange={(e) => handlechange("country_code", e.target.value)} >
                                                            <option defaultValue={" "}></option>
                                                            {country_code.map((row) => (
                                                                <React.Fragment key={row?.name}>
                                                                    <option value={row?.dial_code}>{row?.dial_code}  {row?.name}</option>
                                                                </React.Fragment>
                                                            ))}
                                                        </select>
                                                    </Grid>

                                                    <Grid item lg={4} md={4} sm={4} xs={8} >
                                                        <label className="form-label">Phone No.</label>
                                                        <input required className="form-control" type="text" name="contact" value={obj?.contact} onChange={(e) => handlechange("contact", e.target.value)} />
                                                    </Grid>


                                                    <Grid item lg={12} md={12} sm={12} xs={12} >
                                                        <label className="form-label">Service Provider</label>

                                                        <select className="form-select form-control" required name="manager" value={obj?.distributor} onChange={(e) => handlechange("distributor", e.target.value)}>
                                                            <option defaultValue={""} className="form-control" ></option>
                                                            <option value={""} className="form-control" ></option>
                                                            {owner.map((row) => (
                                                                <React.Fragment key={row?.id}>
                                                                    <option value={row?.id}>{row?.name}</option>
                                                                </React.Fragment>
                                                            ))}
                                                        </select>

                                                    </Grid>

                                                    <Grid item lg={12} md={12} xs={12} >
                                                        {btnloader ? <CircularProgress /> : <button className="default-btn btn-two" style={{ background: "white" }} type="submit">
                                                            Update Details
                                                        </button>}
                                                    </Grid>

                                                    <Grid item lg={12}>
                                                        <div style={{ display: "none" }} id="recaptcha-container"></div>
                                                    </Grid>

                                                </Grid>
                                            </form>


                                        </CardContent>
                                    </Card>

                                </CardContent>
                            }
                        </Grid>

                    </Grid>
                </Grid>
            </Grid >




        </>
    )
}

export default Newprofile