import React from 'react'
import Myheader from './Myheader'
import Notefication from './Notefication'
import { useSelector } from 'react-redux';

const Usermessage = () => {
    const user = useSelector((state) => state?.data?.userdata?.user);
    return (
        <>
            <Myheader />
            <Notefication usertype={"passenger"} email={user?.email} />
        </>
    )
}

export default Usermessage