import React from 'react'
import Driverheader from './Driverheader'
import GAddressTable from '../Components/GAddressTable'
const RiderAddress = () => {
    return (<>
        <Driverheader />
        <GAddressTable Address={"driveraddresses"} usertype={"driver"}/>
    </>
    )
}

export default RiderAddress