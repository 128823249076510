import React, { Fragment, useEffect, useState } from 'react'
import Driverheader from './Driverheader'
import { Button, CardContent, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import { Card } from 'react-bootstrap'
import axios from 'axios'
import { Apiurl } from '../api_urls'
import { GToaster, customshiftvalue, getcustomdate, getcustomformatdate } from '../Components/g_toaster'
import { useDispatch, useSelector } from 'react-redux'
import { Add, DoNotTouch, Forward } from '@mui/icons-material'
import GooglePlaces from '../Components/GooglePlaces'
import Termagree from '../Components/Termagree'
import Gmodal from '../Components/Gmodal'
import { getoldshiftdata } from '../redux_store/Shiftavailabilty'
import Downloadapp from '../Components/Downloadapp'

const toster = new GToaster()
const DriverShiftAvailablity = () => {
    const dispatch = useDispatch()
    const rideruser = useSelector((state) => state?.data?.riderdata?.rider)
    const oldshiftdata = useSelector((state) => state?.data?.ridershiftdata?.oldshiftdata)
    const [shiftdata, setshiftdata] = useState([])
    const [btnloader, setbtnloader] = useState(false)
    const [isloading, setisloading] = useState(false)
    const [isdownloadapp, setisdownloadapp] = useState(true)
    const [morningtimes, setmorningtimes] = useState([])
    const [noontimes, setnoontimes] = useState([])
    const [eveningtimes, seteveningtimes] = useState([])
    const [nighttimes, setnighttimes] = useState([])
    const [editaddress, seteditaddress] = useState(false)
    const [factoryaddress, setfactoryaddress] = useState("")
    const [alladdress, setalladdress] = useState([])
    const [isagree, setisagree] = useState(false)
    const [reconfirm, setreconfirm] = useState(false)
    const [timecut, settimecut] = useState("")
    const [mymodaldata, setmymodaldata] = useState({
        modalshow: false,
        modaltype: "success",
        modalmessage: "raju"
    })

    let regulardata = [
        {
            "shift_type": "Morning",
            "going_time": "",
            "return_time": ""
        },
        {
            "shift_type": "Afternoon",
            "going_time": "",
            "return_time": ""
        },
        {
            "shift_type": "Evening",
            "going_time": "",
            "return_time": ""
        },
        {
            "shift_type": "Night",
            "going_time": "",
            "return_time": ""
        }
    ]

    function closemodal(e) {
        mymodaldata["modalshow"] = e
        mymodaldata["modalmessage"] = ""
        mymodaldata["modaltype"] = ""
        setmymodaldata({ ...mymodaldata })
    }

    async function getrideraddress() {
        await axios.get(`${Apiurl.GET_PARTICULAR_DRIVER_ADDRESSES_URL}${rideruser.id}`).then((res) => {
            if (res.data.status === 200) {
                let newdata = res.data.addresses
                let founddata = newdata.find((ele) => ele?.factory === "yes" && ele?.type === "primary")
                if (founddata) {
                    setfactoryaddress({ ...founddata })
                }

                setalladdress([...newdata])

            }
        }).catch((err) => {
            toster.error({ title: "Something Went Wrong in Pickup Addresses fetch" })
        })
    }

    function gopicadress(e, zip, city, label,lat,long) {
        let myobj = {
            ['id']: 0, ["address"]: label, ["tag"]: "factory", ["postal_code"]: zip, ["city"]: city, ['type']: "primary", ["factory"]: "yes", ["user_id"]: rideruser.id,
            ["lat"]: lat,
            ["long"]: long,
        }
        setfactoryaddress({ ...myobj });

    }

    async function Mydate() {
        setisloading(true)
        await axios.get(`${Apiurl.GET_BOOKING_DAYS_URL}`).then(async (succ) => {
            if (succ.status === 200) {
                let response = succ.data.days
                let data = [...response]

                let selectdata = data.map((ele, index) => {
                    return {
                        ["date"]: ele?.label, ["data"]: [...regulardata], ["mydate"]: ele?.value, ["reversedate"]: getcustomformatdate(ele?.value)
                    }
                })

                await riderallshift([...selectdata])
                setisloading(false)
            }
        }).catch((err) => {
            setisloading(false)
            toster.error({ title: "Something Went worng in fetching dates from Admin" })
        })
    }

    async function getridertimedata(shift) {
        await axios.get(`${Apiurl.RIDER_ALL_SHIFT_TIME}${shift}`).then((response) => {
            if (response.data.status == 200) {
                let extravalue = [{ ["label"]: "Not Available", ["value"]: "" }, { ["label"]: "All", ["value"]: "all" }]

                let responsedata = response.data.data.map((ele) => {
                    return {
                        ["label"]: ele, ["value"]: ele
                    }
                })
                let newdata = [...extravalue, ...responsedata]

                if (shift === "morning") {
                    setmorningtimes(newdata)
                }
                if (shift === "afternoon") {
                    setnoontimes(newdata)
                }
                if (shift === "evening") {
                    seteveningtimes(newdata)
                }
                if (shift === "night") {
                    setnighttimes(newdata)
                }
                return newdata
            } else {
                toster.error({ title: "Something is worng in fetching rider timings" })
            }
        }).catch((err) => {
            toster.error({ title: "Something is worng in fetching rider timings" })
        })
    }


    const handlechange = (key, value, index, innerindex) => {
        let newdata = JSON.parse(JSON.stringify(shiftdata))
        newdata[index]["data"][innerindex][key] = value
        setshiftdata([...newdata])
    }

    const riderallshift = async (data) => {
        await axios.get(`${Apiurl.GET_RIDER_SHIFT_DATA_URL}${rideruser?.id}`).then((response) => {
            if (response.status === 200) {
                if (response.data?.status == 200) {
                    let newdata = response.data.data
                    if (newdata.length > 0) {
                        getingdata(newdata, data)
                    }
                } else {
                    toster.error({ title: "something went wrong please reload your page again" });
                }
            } else {
                toster.error({ title: "something went wrong please reload your page again" });
            }
        }).catch((Err) => {
            toster.error({ title: "something went wrong please reload your page again" });
        })
    }


    const getingdata = (responsedata, shiftdatedata) => {
        let newdata = JSON.parse(JSON.stringify(shiftdatedata))
        for (let index = 0; index < newdata.length; index++) {
            let datedata = newdata[index]?.mydate
            let founddata = responsedata.find((ele) => ele?.shift_date === datedata)
            if (!!founddata) {
                let makearraydata = [
                    {
                        "shift_type": "Morning",
                        "going_time": founddata?.going_morning,
                        "return_time": founddata?.return_morning,
                    },
                    {
                        "shift_type": "Afternoon",
                        "going_time": founddata?.going_afternoon,
                        "return_time": founddata?.return_afternoon
                    },
                    {
                        "shift_type": "Evening",
                        "going_time": founddata?.going_evening,
                        "return_time": founddata?.return_evening
                    },
                    {
                        "shift_type": "Night",
                        "going_time": founddata?.going_night,
                        "return_time": founddata?.return_night
                    }
                ]
                newdata[index]["data"] = [...makearraydata]
            }

        }
        setshiftdata([...newdata])
        dispatch(getoldshiftdata([...newdata]))
    }

    const getshiftcutdata = async () => {
        let data = ""
        await axios.get(Apiurl.GET_BOOKING_TIMECUT_URL).then((res) => {
            if (res.status === 200) {
                if (res.data?.status === 200) {
                    let timecutdata = res.data.data
                    settimecut(timecutdata)
                    data = res.data.data
                }
            } else {
                toster.error({ title: "something went wrong in fetching timecut" });
            }
        })
        return data
    }

    const checkbooking = async () => {
        const shiftvalue = customshiftvalue()
        const datevalue = getcustomdate()
        let timecutdata = await getshiftcutdata()
        let booleandata = ""
        if ((datevalue === timecutdata?.datecut && timecutdata?.morning_status === 1 && shiftvalue === "morning")
            || (datevalue === timecutdata?.datecut && timecutdata?.afternoon_status === 1 && shiftvalue === "afternoon")
            || (datevalue === timecutdata?.datecut && timecutdata?.evening_status === 1 && shiftvalue === "evening")
            || (datevalue === timecutdata?.datecut && timecutdata?.night_status === 1 && shiftvalue === "night")) {
            booleandata = true
        } else {
            booleandata = false
        }
        return booleandata
    }

    const newcheckbooking = async () => {
        let timecutdata = await getshiftcutdata()
        let shift = Object.entries(timecutdata)
        let allshiftdata = shift.filter((ele) => ele[1] === 1 && ele[0].length > 3).map((ele) => {
            let change = ele[0].split("_")
            return change[0]
        })
        let booolval = ""
        let oldfounddata = oldshiftdata.find((ele) => ele?.reversedate === timecutdata?.datecut)
        let newfounddata = shiftdata.find((ele) => ele?.reversedate === timecutdata?.datecut)
        for (let index = 0; index < allshiftdata.length; index++) {
            const shiftelement = allshiftdata[index];
            let oldinnerfounddata = oldfounddata?.data?.find((ele) => ele?.shift_type?.toLowerCase() === shiftelement)
            let newinnerfounddata = newfounddata?.data?.find((ele) => ele?.shift_type?.toLowerCase() === shiftelement)

            if (!(oldinnerfounddata?.going_time === newinnerfounddata?.going_time) || !(oldinnerfounddata?.return_time === newinnerfounddata?.return_time)) {
                booolval = { ["errorval"]: true, ["date"]: timecutdata?.datecut, ["shift"]: shiftelement }
            } else {
                booolval = false
            }
        }

        return booolval
    }


    const Addshift = async () => {
        let errormsg = await newcheckbooking()
        if (errormsg?.errorval) {
            mymodaldata["modalshow"] = true;
            mymodaldata["modaltype"] = "error";
            mymodaldata["modalmessage"] = `Schedule is already lapsed for ${errormsg?.date} (${errormsg?.shift})`
            setmymodaldata({ ...mymodaldata })
        } else {
            setbtnloader(true)
            let address = {
                "addresses": factoryaddress
            }
            if (!!factoryaddress?.address) {
                await axios.post(`${Apiurl.ADD_FACTORY_ADDRESS_URL}`, address).then((response) => {
                    if (response.status === 200) {
                        getrideraddress()
                        seteditaddress(false);
                    }
                }).catch((Err) => {
                    toster.error({ title: "something went wrong please reload your page again" });
                })
            }

            await axios.post(`${Apiurl.ADD_RIDER_SHIFT_DATA_URL}${rideruser?.id}`, shiftdata).then(async (response) => {
                if (response.status === 200) {
                    setbtnloader(false)
                    await Mydate()
                    setreconfirm(false)
                } else {
                    setbtnloader(false)
                    toster.error({ title: "something went wrong please reload your page again" });
                }
            }).catch((Err) => {
                setbtnloader(false)
                toster.error({ title: "something went wrong please reload your page again" });
            })
        }
    }

    const getkeysdata = (obj) => {
        if (obj === "Morning") {
            return morningtimes
        } else if (obj === "Afternoon") {
            return noontimes
        } else if (obj === "Evening") {
            return eveningtimes
        } else {
            return nighttimes
        }
    }

    function changeagreestate(e) {
        setisagree(e)
    }

    async function changestatus() {
        let errormsg = await newcheckbooking()
        if (errormsg?.errorval) {
            mymodaldata["modalshow"] = true;
            mymodaldata["modaltype"] = "error";
            mymodaldata["modalmessage"] = `Schedule is already lapsed for ${errormsg?.date} (${errormsg?.shift})`
            setmymodaldata({ ...mymodaldata })
        } else {
            setreconfirm(true)
        }
    }

    function disabledoption(timecutdata, shift_type, reverse_Date) {
        if (((reverse_Date === timecutdata?.datecut && timecutdata?.morning_status === 1 && shift_type === "Morning")
            || (reverse_Date === timecutdata?.datecut && timecutdata?.afternoon_status === 1 && shift_type === "Afternoon")
            || (reverse_Date === timecutdata?.datecut && timecutdata?.evening_status === 1 && shift_type === "Evening")
            || (reverse_Date === timecutdata?.datecut && timecutdata?.night_status === 1 && shift_type === "Night"))) {
            return true
        } else {
            return false
        }
    }

    async function checkdata(shift_type, reverse_Date) {
        let timecutdata = await getshiftcutdata()
        if (((reverse_Date === timecutdata?.datecut && timecutdata?.morning_status === 1 && shift_type === "Morning")
            || (reverse_Date === timecutdata?.datecut && timecutdata?.afternoon_status === 1 && shift_type === "Afternoon")
            || (reverse_Date === timecutdata?.datecut && timecutdata?.evening_status === 1 && shift_type === "Evening")
            || (reverse_Date === timecutdata?.datecut && timecutdata?.night_status === 1 && shift_type === "Night"))) {
            mymodaldata["modalshow"] = true;
            mymodaldata["modaltype"] = "error";
            mymodaldata["modalmessage"] = `Schedule is Already Lapsed For  ${reverse_Date} (${shift_type})`
            setmymodaldata({ ...mymodaldata })
        }
    }


    function closedownload(e) {
        setisdownloadapp(e)
    }

    useEffect(() => {
        Mydate()
        getridertimedata("morning")
        getridertimedata("afternoon")
        getridertimedata("evening")
        getridertimedata("night")
        getrideraddress()
        getshiftcutdata()
    }, [])






    return (<>
        <Driverheader />
        {mymodaldata.modalshow == true && <Gmodal closemodal={closemodal} handledata={mymodaldata} />}
        {isdownloadapp ? <>
            <Downloadapp isdownload={isdownloadapp} closedownload={closedownload} usertype={"rider"} />
        </> : <>
            {isagree ? <>
                <Grid container spacing={5}>
                    <Grid item lg={8} md={10} sm={10} xs={12} sx={{ m: "auto", mt: 5 }}>
                        <Card>
                            <CardContent>
                                {isloading ? <center><CircularProgress /></center> :
                                    <Fragment>

                                        <Grid container sx={reconfirm ? { cursor: "not-allowed", pointerEvents: "none" } : {}} spacing={window.innerWidth < 1024 ? 2 : 4}>
                                            <Grid item xs={12}>
                                                <Typography variant='h5' sx={{ py: 2 }}>Add your shift availability</Typography>
                                                <Divider />
                                            </Grid>

                                            <Grid item xs={12} sx={{ background: "#117fb7", ml: window.innerWidth < 1024 ? 2 : 4, p: 2 }}>
                                                <Typography variant='h6' sx={{ textTransform: "capitalize", color: "white" }}>Add Your Job Address
                                                    &nbsp;&nbsp;<span onClick={() => seteditaddress(!editaddress)} style={{ cursor: "pointer" }}><u><Add />{editaddress ? "Old" : "New"} Address</u></span>
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sx={{ pb: 4 }} className="inputpad" >

                                                {editaddress ? (<>
                                                    <GooglePlaces gopicadress={gopicadress} label={"gopicadress"} />
                                                </>) : (<>
                                                    <select style={{ background: "#f7f7f8" }} className="form-select form-control" name="address" required id="pickadress" value={JSON.stringify(factoryaddress)} onChange={(e) => setfactoryaddress(JSON.parse(e.target.value))}>
                                                        <option defaultValue={""} className="form-control" ></option>
                                                        {alladdress.map((row) => {
                                                            return (<React.Fragment key={row.id}>
                                                                {row?.factory === "yes" && <option value={JSON.stringify(row)} >
                                                                    {row.address}
                                                                </option>
                                                                }
                                                            </React.Fragment>)
                                                        })}
                                                    </select>
                                                </>)}

                                            </Grid>

                                            {shiftdata.map((obj, index) => (
                                                <Fragment key={index}>
                                                    <Grid xs={12} sx={{ background: "#117fb7", ml: window.innerWidth < 1024 ? 2 : 4, p: 2 }}>
                                                        <Typography variant='h6' sx={{ textTransform: "capitalize", color: "white" }}>
                                                            {obj?.date.split(" - ")[0]} {obj?.date.split(" - ")[1]}
                                                        </Typography>
                                                    </Grid>

                                                    {window.innerWidth < 1024 ? <>

                                                        {obj?.data?.map((ele, innerindex) => (
                                                            <Fragment key={innerindex}>
                                                                <Grid item lg={3} md={3} sm={6} xs={6} >
                                                                    <label className="form-label">Going {ele?.shift_type}</label>
                                                                    <select onClick={() => checkdata(ele?.shift_type, obj?.reversedate)} className="form-select form-control form-select-lg"
                                                                        name="going_time" value={ele?.going_time ?? ""} onChange={(e) => handlechange(e.target.name, e.target.value, index, innerindex)} >
                                                                        {getkeysdata(ele?.shift_type)?.map((elm, index) => (
                                                                            <option disabled={disabledoption(timecut, ele?.shift_type, obj?.reversedate)} key={index} value={elm?.value}>{elm?.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </Grid>

                                                                <Grid item lg={3} md={3} sm={6} xs={6}    >
                                                                    <label className="form-label">Return {ele?.shift_type}</label>
                                                                    <select onClick={() => checkdata(ele?.shift_type, obj?.reversedate)} className="form-select form-control form-select-lg" name="return_time" value={ele?.return_time ?? ""} onChange={(e) => handlechange(e.target.name, e.target.value, index, innerindex)} >
                                                                        <option value={""}>Not Available </option>
                                                                        {getkeysdata(ele?.shift_type)?.map((elm, index) => (
                                                                            <option disabled={disabledoption(timecut, ele?.shift_type, obj?.reversedate)} key={index} value={elm?.value}>{elm?.label}</option>
                                                                        ))}
                                                                    </select>

                                                                </Grid>

                                                            </Fragment>
                                                        ))}

                                                    </> : <>

                                                        {obj?.data?.map((ele, innerindex) => (
                                                            <Fragment key={innerindex}>
                                                                <Grid item lg={3} md={3} sm={6} xs={12} >
                                                                    <label className="form-label">Going {ele?.shift_type}</label>
                                                                    <select
                                                                        onClick={() => checkdata(ele?.shift_type, obj?.reversedate)}
                                                                        className="form-select form-control form-select-lg" name="going_time" value={ele?.going_time ?? ""} onChange={(e) => handlechange(e.target.name, e.target.value, index, innerindex)} >
                                                                        {getkeysdata(ele?.shift_type)?.map((elm, index) => (
                                                                            <option disabled={disabledoption(timecut, ele?.shift_type, obj?.reversedate)} key={index} value={elm?.value}>{elm?.label}</option>
                                                                        ))}


                                                                    </select>
                                                                </Grid>
                                                            </Fragment>
                                                        ))}

                                                        {obj?.data?.map((ele, innerindex) => (
                                                            <Fragment key={innerindex}>
                                                                <Grid item lg={3} md={3} sm={6} xs={12}    >
                                                                    <label className="form-label">Return {ele?.shift_type}</label>
                                                                    <select
                                                                        onClick={() => checkdata(ele?.shift_type, obj?.reversedate)}
                                                                        className="form-select form-control form-select-lg" name="return_time" value={ele?.return_time ?? ""} onChange={(e) => handlechange(e.target.name, e.target.value, index, innerindex)} >
                                                                        {getkeysdata(ele?.shift_type)?.map((elm, index) => (
                                                                            <option disabled={disabledoption(timecut, ele?.shift_type, obj?.reversedate)} key={index} value={elm?.value}>{elm?.label}</option>
                                                                        ))}

                                                                    </select>

                                                                </Grid>
                                                            </Fragment>
                                                        ))}


                                                    </>}



                                                    <Grid item xs={12}>
                                                        <Divider />
                                                    </Grid>

                                                </Fragment>
                                            ))}
                                        </Grid>
                                        <Grid container spacing={window.innerWidth < 1024 ? 2 : 4}>

                                            {reconfirm ?
                                                <>
                                                    <Grid item xs={12} sx={{ fontSize: "15px" }}> <DoNotTouch /> For Continue Form Filling Cick On <Forward /> Continue Edit Button</Grid>
                                                    <Grid item md={3} xs={6}>
                                                        <Button onClick={() => setreconfirm(false)} variant='contained' fullWidth color='primary'>
                                                            Edit
                                                        </Button>
                                                    </Grid>
                                                    <Grid item md={3} xs={6}>
                                                        <Button onClick={Addshift} variant='contained' fullWidth color='success'>
                                                            {btnloader ? <CircularProgress sx={{ color: "white" }} size={30} /> : "Confirm"}
                                                        </Button>
                                                    </Grid>
                                                </> :
                                                <Grid item md={3} xs={6}>
                                                    <Button onClick={changestatus} variant='contained' fullWidth color='success'>
                                                        Reconfirm Your Shift Details
                                                    </Button>
                                                </Grid>
                                            }

                                        </Grid>
                                    </Fragment>
                                }

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </> : <>
                <Termagree isagree={isagree} usertype={"rider"} handlechange={changeagreestate} />
            </>}

        </>}


    </>)
}

export default DriverShiftAvailablity;