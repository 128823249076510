import { Button, Card, CardContent, CircularProgress, Fab, Grid, IconButton, InputLabel, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Driverheader from "./Driverheader";
import { useSelector } from "react-redux";
import { Apiurl } from "../api_urls";
import axios from "axios";
import { GToaster } from "../Components/g_toaster";
import { CalendarMonth } from "@mui/icons-material";
import SearchFilter from "react-filter-search";

const toster = new GToaster()
function RiderHome() {
    const navi = useNavigate();
    const [loading, setloading] = useState(false);
    const [filterdatamodal, setfilterdatamodal] = useState(false)
    const [inp2, setinp2] = useState("")
    const [mydata, setmydata] = useState([]);
    const driver = useSelector((state) => state?.data?.riderdata?.rider);
    let today = new Date();
    today.setDate(today.getDate() + 7)
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    let todaydate = yyyy + "-" + mm + "-" + dd;

    const getassignstudent = async () => {
        setloading(true)
        await axios.get(`${Apiurl.ASSIGN_DRIVER_URL}${driver.id}`).then((res) => {
            if (res.data.status === 200) {
                setloading(false)
                setmydata(res.data.data)
            } else {
                setloading(false)
                setmydata([])
            }
        }).catch((err) => {
            toster.error({ title: "Something went wrong Bookings data fetch" })
        })
    }


    async function filterdata(e) {
        e.preventDefault();
        let data = new FormData(e.currentTarget);
        let startdate = data.get("startdate")
        let enddate = data.get("enddate")
        await axios.get(`${Apiurl.RIDER_DATE_RANGE_FILTER_URL}${driver.id}/${startdate}/${enddate}`).then((response) => {

            if (response.data.status === 200) {
                setmydata(() => [...response.data.data])
                setfilterdatamodal(false)
            } else if (response.data.status === 300) {
                toster.warning({ title: "There is no assign bookings available on the selected date" })
            } else {
                toster.error({ title: "Server Error" })
            }
        }).catch((Err) => {
            console.log(Err)
        })

    }

    useEffect(() => {
        if (!driver) {
            navi("/rider")
        } else {
            getassignstudent()
        }
    }, [])




    return (
        <>
            <Driverheader />
            <Grid container spacing={2}>

                <Modal open={filterdatamodal} onClose={() => setfilterdatamodal(false)}>
                    <Grid item lg={4} xs={12} sx={{ m: "auto", mt: 25 }}>
                        <Card>
                            <CardContent>
                                <form onSubmit={filterdata}>
                                    <Grid container spacing={4}>
                                        <Grid item lg={12} xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h5" >
                                                Filter Your Assign List
                                            </Typography>
                                            <Button onClick={() => setfilterdatamodal(false)}>x</Button>
                                        </Grid>
                                        <Grid item lg={6} xs={6}>
                                            <InputLabel sx={{ pb: 1 }}>Select Start Date</InputLabel>
                                            <TextField fullWidth size="small" type="date" inputProps={{ max: todaydate, min: "2000-01-01" }} name="startdate" />
                                        </Grid>
                                        <Grid item lg={6} xs={6}>
                                            <InputLabel sx={{ pb: 1 }}>Select End Date</InputLabel>
                                            <TextField fullWidth size="small" type="date" inputProps={{ max: todaydate, min: "2000-01-01" }} name="enddate" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained" color="secondary" fullWidth>Submit</Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Modal>

                <Grid item lg={12} sm={12} xs={12}>
                    <Grid container spacing={5} sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Grid item lg={3} sm={5} xs={8}>
                            <CardContent>
                                <TextField size="small" label="Search Here..." fullWidth value={inp2} onChange={(e) => setinp2(e.target.value)} />
                            </CardContent>
                        </Grid>
                        
                        <Grid item lg={9} sm={7} xs={4} sx={{ mt: 5, textAlign: "right", pr: 6 }}>
                            <Tooltip arrow title="Calendar">
                                <Fab size='small' variant="contained" color="secondary" onClick={() => setfilterdatamodal(true)} ><CalendarMonth /></Fab>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="h4" sx={{ pb: 2, textAlign: "center" }}>Assigned Passenger Details</Typography>
                    <Card>
                        {loading ? <center><CircularProgress /></center>
                            :
                            mydata.length > 0 ?
                                <TableContainer style={{ maxHeight: "63vh" }}>
                                    <Table >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" ><b>Sr. No.</b></TableCell>
                                                <TableCell align="center" ><b>Booking Date</b></TableCell>
                                                <TableCell align="center" ><b>Shift</b></TableCell>
                                                <TableCell align="center" ><b>Timing</b></TableCell>
                                                <TableCell align="center" ><b>Passenger Name</b></TableCell>
                                                <TableCell align="center" ><b>Mobile No.</b></TableCell>
                                                <TableCell align="center" ><b>Pickup location</b></TableCell>
                                                <TableCell align="center" ><b>Dropup location</b></TableCell>
                                                <TableCell align="center" ><b>Pickup City</b></TableCell>
                                                <TableCell align="center" ><b>Dropup City</b></TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            <SearchFilter
                                                value={inp2}
                                                data={mydata}
                                                renderResults={(results) => (
                                                    <>
                                                        {results.map((row, index) => (
                                                            <TableRow key={row.id}>
                                                                <TableCell align="center" > {index + 1}</TableCell>
                                                                <TableCell align="center" > {row.booked_date}</TableCell>
                                                                <TableCell align="center" > {row.shift}</TableCell>
                                                                <TableCell align="center" > {row.booked_time}</TableCell>
                                                                <TableCell align="center" > {row.name}</TableCell>
                                                                <TableCell align="center" > {row.mobile}</TableCell>
                                                                <TableCell align="center" > {row.pickup_location}</TableCell>
                                                                <TableCell align="center" > {row.dropup_location}</TableCell>
                                                                <TableCell align="center" > {row.pickup_city}</TableCell>
                                                                <TableCell align="center" > {row.dropup_city}</TableCell>
                                                            </TableRow>
                                                        ))}

                                                    </>)} />

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <center>
                                    No Data Found
                                </center>
                        }
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default RiderHome;