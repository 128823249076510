import React from 'react'
import Myheader from './Myheader'
import GAddressTable from './GAddressTable'


const Address = () => {
    return (<>
        <Myheader />
        <GAddressTable Address={"addresses"} usertype={"user"} />
    </>
    )
}

export default Address