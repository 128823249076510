import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Apiurl } from '../api_urls'
import { GToaster, customDateFormat } from './g_toaster'
import { Button, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, Modal, Typography } from '@mui/material'
import { Interweave } from 'interweave'
const toster = new GToaster()

const Termagree = ({ usertype, handlechange, isagree }) => {
    const [getdata, setgetdata] = useState(null)
    const getcondition = async () => {
        await axios.get(Apiurl.GET_TERM_CONDITION_URL).then((response) => {
            if (response.status === 200) {
                if (response.data?.status === 200) {
                    setgetdata(response.data.data)
                } else {
                    toster.error({ title: "Server Error" });
                }
            } else {
                toster.error({ title: "Server Error" });
            }
        })
    }

    const changestate = (e) => {
        handlechange(e)
    }

    useEffect(() => {
        getcondition()
    }, [])


    return (
        <>
            <Grid container spacing={5} >
                <Grid item md={6} xs={11} sx={{ m: "auto", mt: { md: 12, xs: 5 } }}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant='h5'>Term & Condition</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <hr />
                                </Grid>
                                {!getdata ? <CircularProgress /> :
                                    <Grid item xs={12}>
                                        <Typography paragraph={true} textAlign={"justify"} variant='body1' padding={1}>
                                            <Interweave content={usertype === "passenger" ? getdata?.passenger_message : getdata?.driver_message} />
                                        </Typography>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <hr />
                                </Grid>

                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Button variant='contained' onClick={() => changestate(!isagree)}>Agree</Button>

                                    {/* <Typography textAlign={"right"} fontWeight={"bold"} fontSize={{ xs: 15, md: 17 }}   >
                                        {customDateFormat(getdata?.created_at, 'DD MMM YYYY h:mm:ss a')}
                                    </Typography> */}
                                </Grid>

                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default Termagree