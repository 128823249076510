import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../Firebase";
import firebase from "firebase";
import { country } from "../Allapis";
import { Apiurl } from "../api_urls";
import { riderlogin } from "../redux_store/RiderloginSlice";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { GToaster } from "../Components/g_toaster";
import { CircularProgress } from "@material-ui/core";
const toster = new GToaster()

function Riderlogin() {
  const objdata = {
    "country_code": "",
    "contact_number": ""
  }
  let navi = useNavigate();
  const dispatch = useDispatch()
  const url = Apiurl.DRIVER_LOGIN_URL
  const [obj, setobj] = useState({ ...objdata })
  const [otpdata, setotpdata] = useState(null);
  const [code, setcode] = useState(country);
  const [userdata, setuserdata] = useState(null);
  const [btnloader, setbtnloader] = useState(false)

  function handlechange(key, value) {
    setobj({ ...obj, [key]: value })
  }

  async function logdata(e) {
    e.preventDefault();
    setbtnloader(true)
    localStorage.removeItem("persist:root")
    await axios.post(url, obj).then((response) => {
      if (response.data.status == 300) {
        toster.warning({ title: "This contact no. is not registered please check your contact" })
        setbtnloader(false)
      } else if (response.data.status == 202) {
        setbtnloader(false)
        toster.warning({ title: "Your account status is inactive please contact the admin" })
      } else if (response.data.status == 200) {
        setuserdata(response.data.data)
        const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
          'size': 'invisible'
        });
        const phoneNumber = `${obj.country_code}${obj.contact_number}`;
        auth.signInWithPhoneNumber(phoneNumber, appVerifier)
          .then((confirmationResult) => {
            setotpdata(confirmationResult);
            setbtnloader(false)
            toster.success({ title: "Otp is send to your registered mobile number please verify" })
          }).catch((error) => {
            toster.error({ title: "something went wrong please reload your page again" })
            setbtnloader(false)
          })
      } else {
        toster.error({ title: "Something went wrong in login user" })
        setbtnloader(false)
      }
    }).catch((err) => {
      toster.error({ title: "Something went wrong in login user" })
      setbtnloader(false)
    })
  }


  function verify(e) {
    e.preventDefault()
    let data = new FormData(e.currentTarget);
    let v = data.get("Verify");
    setbtnloader(true)
    otpdata.confirm(v).then((succ) => {
      toster.success({ title: "LoggedIn Successfully" })
      setobj({ ...objdata })
      setbtnloader(false)
      dispatch(riderlogin(userdata))
      setotpdata(null)
      localStorage.removeItem("driver")
      localStorage.removeItem("drivername")
      navi("/riderprofile")
      window.location.reload()
    }).catch((err) => {
      toster.error({ title: "something went wrong please reload your page again" })
    })
  }


  function resend() {
    const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible'
    });
    const phoneNumber = `${obj.country_code}${obj.contact_number}`;
    auth.signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        setotpdata(confirmationResult);
        toster.success({ title: "Otp is send to your registered mobile number please verify" })
      }).catch((error) => {
        toster.error({ title: "something went wrong please reload your page again" });
      })
  }


  return (
    <>
      <div className="driverreg">
        {otpdata ?
          <section className="sign-up-area pt-70 pb-70">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-6 col-md-8 col-sm-10 col-12">
                  <div className="contact-form-action">
                    <div className="form-heading text-center">
                      <h3 className="form-title">Verify Your Otp</h3>
                      <p className="reset-desc">Enter  your six-digit otp that will receive on your  Contact No. If you have any issue about otp receive then contact us.</p>
                    </div>
                    <form onSubmit={verify}>
                      <div className="row">
                        <div className="col-lg-12 col-sm-12 col-12">
                          <div className="form-group">
                            <input className="form-control" type="text" name="Verify" placeholder="Enter Your Otp" required />
                          </div>
                        </div>

                        <div className="col-lg-12 col-sm-12 col-12">
                          <div className="row">
                            <div className="col-6 d-flex justify-content-start">
                              <button type="button" className="btn btn-outline-primary mt-4" onClick={resend}>
                                Resend Otp
                              </button>
                            </div>

                            <div className="col-6 d-flex justify-content-end">
                              {btnloader ? <CircularProgress /> : <button className="default-btn btn-two mt-4 m-0" type="submit">
                                Verify Otp
                              </button>
                              }
                            </div>
                          </div>
                        </div>
                        <div style={{ display: "none" }} id="recaptcha-container"></div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          :
          <section className="sign-up-area">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 col-12">
                  <div className="contact-form-action">
                    <div className="form-heading text-center mb-3">
                      <h3 className="form-title">Login to your account!</h3>
                      <p className="form-desc">with your social network.</p>
                    </div>
                    <form onSubmit={logdata}>
                      <div className="row">

                        <div className="col-lg-3 col-sm-4 col-4 mb-2">
                          <label className="form-label">Country </label>
                          <select className="form-select form-control form-select-lg" value={obj?.country_code} name="country_code" onChange={(e) => handlechange(e.target.name, e.target.value)} required  >
                            <option defaultValue={" "}></option>
                            {code.map((row) => (
                              <React.Fragment key={row.name}>
                                <option value={row.dial_code}>{row.dial_code}  {row.name}</option>
                              </React.Fragment>
                            ))}
                          </select>
                        </div>

                        <div className="col-lg-9 col-sm-8 col-8 mb-2">
                          <label className="form-label">Phone No.</label>
                          <input required className="form-control mb-3" type="tel" name="contact_number" value={obj?.contact_number} onChange={(e) => handlechange(e.target.name, e.target.value)} maxLength={"10"} />
                        </div>

                        <div className="col-lg-12 col-sm-12 col-12">
                          {btnloader ? <CircularProgress /> :
                            <button className="default-btn btn-two" type="submit">
                              Log In Now
                            </button>
                          }
                        </div>
                        <div className="col-lg-12 col-sm-12 col-12">
                          <p className="account-desc">
                            Not a member?
                            <a style={{ cursor: "pointer" }} onClick={() => navi("/ridersignup")}>Register</a>
                          </p>
                        </div>
                        <div style={{ display: "none" }} id='recaptcha-container'></div>

                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }


      </div>
    </>
  );
}

export default Riderlogin;
