import { Button, Card, CardContent, Grid, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import "../App.css"
import phone1 from "../Components/img/offivepng.png"
import { Apple } from '@material-ui/icons'
import { Adb } from '@mui/icons-material'
import QRCode from 'qrcode.react'


const Downloadapp = ({ usertype, closedownload, isdownload }) => {
    const applinks = usertype == "user" ?
        {
            playstorelink: "https://play.google.com/store/apps/details?id=com.pick_and_drop",
            appstorelink: "https://apps.apple.com/in/app/pickk-and-drop/id6478844582"
        }
        :
        {
            playstorelink: "https://play.google.com/store/apps/details?id=com.pick_and_drop_driver",
            appstorelink: "https://apps.apple.com/us/app/pickk-and-drop-driver/id6480036122"
        }

    const qrdata = [
        { label: "Download App On Android", qr: applinks?.playstorelink, buttonicon: <Adb /> },
        { label: "Download App On Ios", qr: applinks?.appstorelink, buttonicon: <Apple /> }
    ]


    function changestate(e) {
        closedownload(e)
    }

    return (
        <Grid container spacing={5} >
            <Grid item md={6} xs={11} sx={{ m: "auto", mt: { md: 8, xs: 2 } }}>
                <Card className='raju'>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={4.5} sx={{ display: { md: "block", xs: "none" } }}>
                                <img src={phone1} style={{ padding: "10px", width: "100%" }} />
                            </Grid>
                            <Grid item md={7.5} sm={12} xs={12}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <Typography variant='h3' fontSize={"55px"} fontWeight={"bold"} textAlign={"center"}>Download</Typography>
                                        <Typography variant='h4' fontSize={"35px"} fontWeight={"bold"} textAlign={"center"}>Our App</Typography>
                                    </Grid>
                                    {qrdata.map((ele, index) => (<Fragment key={index}>
                                        {!!ele?.qr && <Grid item sm={6} xs={12} textAlign={"center"}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sx={{ textAlign: "center" }}>
                                                            <Typography variant='h6' fontSize={"19px"} fontWeight={"bold"}>{ele?.label} :</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <QRCode
                                                                id="123456"
                                                                value={ele?.qr}
                                                                size={270}
                                                                level={"H"}
                                                                includeMargin={true}
                                                                style={{ padding: "8px", width: "100%" }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sx={{ textAlign: "center" }}>
                                                    <a href={ele?.qr} target='_blank' style={{ fontSize: "17px", background: "black", color: "white", width: "100%", padding: "10px 0px", borderRadius: "5px", alignItems: "center", textDecoration: "none" }} fullWidth >Download On {ele?.buttonicon}</a>
                                                </Grid>
                                            </Grid>
                                        </Grid>}
                                    </Fragment>))}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <hr />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant='contained' onClick={() => changestate(!isdownload)}>Skip</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default Downloadapp