const BASE_URL = 'https://pickanddrop.app/backend/api/';
// process.env.NODE_ENV === "development" ? 'https://development.pickanddrop.app/api/' : 
export const Apiurl = {
    DELETE_ADDRESS_URL: `${BASE_URL}deleteaddresses`,
   
    //==================== USER COMPONENT APIS========================  //
   
    USER_LOGIN_URL: `${BASE_URL}passengerlogin`,
    USER_LIST_URL: `${BASE_URL}passengersfetch`,
    USER_REGISTERATION_URL: `${BASE_URL}passengerregistration`,
    GET_PARTICULAR_USER_DATA_URL: `${BASE_URL}passengersfetch/`,
    UPDATE_USER_PROFILE_URL: `${BASE_URL}profileupdate`,
    GET_USER_ADDRESSES_NEW_URL: `${BASE_URL}getaddresses/`,
    GET_SUPPORT_DETAILS_URL: `${BASE_URL}support`,
    GET_NOTIFICATION_COUNTER_URL: `${BASE_URL}getnotification`,
    GET_NOTIFICATION_MESSAGE_URL: `${BASE_URL}changenotification`,

    //============================= BOOKING RELATED APIS====================//

    GET_PICK_UP_CITY_URL: `${BASE_URL}pickupcityfetch`,
    GET_DROP_UP_CITY_URL: `${BASE_URL}dropupcityfetch`,
    GET_BOOKING_AGENCIES_URL: `${BASE_URL}bookingagenyies`,
    ADD_BOOKING_URL: `${BASE_URL}passengersbooking`,
    GET_PARTICULAR_USER_BOOKING_DATA_URL: `${BASE_URL}bookinguser/`,
    GET_PARTICULAR_CITY_SUBPOINT_URL: `${BASE_URL}pickupsubpointcityfetch/`,
    GET_BOOKING_SHIFT_TIME_URL: `${BASE_URL}shifttime/`,
    GET_BOOKING_DAYS_URL: `${BASE_URL}bookingdays`,
    GET_BOOKING_CLOSE_TIME_URL: `${BASE_URL}bookingclosetime/`,
    CANCEL_BOOKING_URL: `${BASE_URL}bookingcancel/`,
    UPDATE_BOOKING_URL: `${BASE_URL}bookingupdate`,
    DATE_RANGE_FILTER_URL: `${BASE_URL}rangedatefilter/`,
    GET_PARTICULAR_USER_NEW_BOOKING_LIST_URL: `${BASE_URL}bookinglist/`,
    GET_PARTICULAR_SINGLE_BOOKING_DATA_URL: `${BASE_URL}bookingassignsingle/`,
    GET_BOOKING_TIMECUT_URL: `${BASE_URL}timecut`,


    //============================= DRIVER COMPONENT APIS================ //

    DRIVER_LIST_URL: `${BASE_URL}driverall`,
    UPDATE_DRIVER_URL: `${BASE_URL}driverupdate`,
    ASSIGN_DRIVER_URL: `${BASE_URL}driverassign/`,
    DRIVER_REGISTRATION_URL: `${BASE_URL}driverregister`,
    DRIVER_LOGIN_URL: `${BASE_URL}driverlogin`,
    GET_DRIVER_PROFILE_DETAILS_URL: `${BASE_URL}driverdetail/`,
    GET_PARTICULAR_DRIVER_ADDRESSES_URL: `${BASE_URL}getaddressdriver/`,
    RIDER_DATE_RANGE_FILTER_URL: `${BASE_URL}driverassignfilter/`,
    RIDER_ALL_SHIFT_TIME: `${BASE_URL}shifttimeall/`,
    ADD_RIDER_SHIFT_DATA_URL: `${BASE_URL}drivershiftsetting/`,
    GET_RIDER_SHIFT_DATA_URL: `${BASE_URL}drivershiftget/`,
    ADD_FACTORY_ADDRESS_URL: `${BASE_URL}savefactoryaddress`,
    GET_TERM_CONDITION_URL: `${BASE_URL}termcondition`,

}

