import { Button, CardContent, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Driverheader from "./Driverheader";
import { useDispatch, useSelector } from "react-redux";
import { Apiurl } from "../api_urls";
import { GToaster } from "../Components/g_toaster";
import axios from "axios";
import { editriderdata } from "../redux_store/RiderloginSlice";

const toster = new GToaster()
function Driverprofile() {
    const navi = useNavigate();
    const driver = useSelector((state) => state?.data?.riderdata?.rider)
    const [userdata, setuserdata] = useState([driver])
    const [loading, setloading] = useState(false)
    const dispatch = useDispatch()

    async function getuserdata() {
        setloading(true)
        await axios.get(`${Apiurl.GET_DRIVER_PROFILE_DETAILS_URL}${driver.id}`).then((res) => {
            if (res.data.status === 200) {
                setloading(false)
                setuserdata([res.data.data])
            } else {
                setloading(false)
                setuserdata([])
            }
        }).catch((err) => {
            toster.error({ title: "Something went wrong Bookings data fetch" })
        })


    }

    function path(e) {
        dispatch(editriderdata(e))
        navi("/editriderdata")
        localStorage.removeItem("driver")
        localStorage.removeItem("drivername")
        window.location.reload()
    }

    useEffect(() => {
        getuserdata()
    }, [])


    return (

        <>

            <Driverheader />
            <Grid container>

                <Grid item lg={11} md={11} sm={11} xs={12} sx={{ m: "auto", mt: 2 }}>
                    <Grid container spacing={6}>

                        <Grid item lg={8} md={12} sm={12} xs={12} sx={{ m: "auto" }}>
                            <CardContent>
                                <Card>
                                    <CardContent>
                                        {userdata.map((row) => (
                                            <React.Fragment key={row?.id}>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Typography variant="h5" sx={{ py: 1 }}> Your Profile Details</Typography>
                                                    <Button variant="contained" color="success" onClick={() => path(row)}>Update your Details</Button>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3 col-xs-5 col-3">
                                                        <p className="mb-0">Full Name</p>
                                                    </div>
                                                    <div className="col-sm-9 col-xs-8 col-9">
                                                        <p className="text-muted mb-0">{row?.name}</p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3 col-xs-5 col-3">
                                                        <p className="mb-0">Email</p>
                                                    </div>
                                                    <div className="col-sm-9 col-xs-8 col-9">
                                                        <p className="text-muted mb-0">{row?.email}</p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3 col-xs-5 col-3">
                                                        <p className="mb-0">Mobile Number</p>
                                                    </div>
                                                    <div className="col-sm-9 col-xs-8 col-9">
                                                        <p className="text-muted mb-0">{row?.mobile}</p>
                                                    </div>
                                                </div>
                                                <hr />

                                                <div className="row">
                                                    <div className="col-sm-3 col-xs-5 col-3">
                                                        <p className="mb-0">Age</p>
                                                    </div>
                                                    <div className="col-sm-9 col-xs-8 col-9">
                                                        <p className="text-muted mb-0">{row?.age}</p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3 col-xs-5 col-3">
                                                        <p className="mb-0">Gender</p>
                                                    </div>
                                                    <div className="col-sm-9 col-xs-8 col-9">
                                                        <p className="text-muted mb-0">{row?.gender}</p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3 col-xs-5 col-3">
                                                        <p className="mb-0">Address</p>
                                                    </div>
                                                    <div className="col-sm-9 col-xs-8 col-9">
                                                        <p className="text-muted mb-0">{row?.address?.address}</p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3 col-xs-5 col-3">
                                                        <p className="mb-0">City</p>
                                                    </div>
                                                    <div className="col-sm-9 col-xs-8 col-9">
                                                        <p className="text-muted mb-0">{row?.address?.city}</p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3 col-xs-5 col-3">
                                                        <p className="mb-0">Subpoint</p>
                                                    </div>
                                                    <div className="col-sm-9 col-xs-8 col-9">
                                                        <p className="text-muted mb-0">{row?.address?.subpoint}</p>
                                                    </div>
                                                </div>

                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3 col-xs-5 col-3">
                                                        <p className="mb-0">Vehicle Number</p>
                                                    </div>
                                                    <div className="col-sm-9 col-xs-8 col-9">
                                                        <p className="text-muted mb-0">{row?.vehicle_number}</p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3 col-xs-5 col-3">
                                                        <p className="mb-0">License Number</p>
                                                    </div>
                                                    <div className="col-sm-9 col-xs-8 col-9">
                                                        <p className="text-muted mb-0">{row?.license_number}</p>
                                                    </div>
                                                </div>



                                            </React.Fragment>
                                        ))}


                                    </CardContent>
                                </Card>
                            </CardContent>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>


        </>

    )


}

export default Driverprofile;