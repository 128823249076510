import React, { useEffect, useState } from "react";
import lof from "./img/logo-main.png"
import { CCollapse } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginuser } from "../redux_store/LoginSlice";
import { seteditmybooking } from "../redux_store/BookingDataSlice";
import Notificationcounter from "./Notificationcounter";


function Myheader() {
    let navi = useNavigate();
    const [show, setshow] = useState(false)
    const user = useSelector((state) => state?.data?.userdata?.user);
    const dispatch = useDispatch()



    function logout() {
        if (window.confirm("Are You Really Want To Logout Your Account ?")) {
            dispatch(loginuser(null))
            localStorage.removeItem("persist:root")
            navi("/signin")
        }
    }


    useEffect(() => {
        if (!user) {
            navi("/signin")
        }
    }, [])

    return (
        <>


            {/* <div className="mininav">
                <div className="container">
                    <div className="list1">
                        <div className="inncon"><i className="bx bx-envelope"></i></div>
                        <div className="inn"><a href="mailto: info@pickanddrop.app">info@pickanddrop.app</a></div>
                        <div className="inncon"><i className=" bx bx-phone"></i></div>
                        <div className="inn"><a href="tel:+14372284743">+14372284743</a></div>
                        <div className="inncon"><i className=" bx bx-phone"></i></div>
                        <div className="inn"><a href="tel:+919216086981">+919216086981</a></div>


                    </div>

                </div>
            </div> */}

            <div className="navbar1">
                <div className="container">
                    <div className="logopic" onClick={() => navi("/home")} style={{ cursor: "pointer" }}>
                        <img src={lof} className="img-fluid" />
                    </div>

                    <div className="mylist">
                        {user && (<>
                            <div >
                                <a className="inn " onClick={() => {
                                    navi("/home")
                                    dispatch(seteditmybooking(null))
                                }}>Book Ride</a>
                            </div>

                            <div>
                                <a className="inn" onClick={() => navi("/mybookings")}>Mybookings</a>
                            </div>

                            <div>
                                <a className="inn" onClick={() => navi("/profile")}>MyProfile</a>
                            </div>

                            <div className="inn" onClick={() => navi("/address")}>Saved Address</div>
                            <div className="inn" onClick={() => navi("/usersupport")}>Support</div>
                            <div className="inn" onClick={logout}>Logout</div>

                            <div className="inncon">
                                <i onClick={() => setshow(!show)} className={show ? "bx bx-x" : "bx bx-menu"} ></i>
                            </div>

                        </>)}

                    </div>

                </div>
                {user && (<>
                    <CCollapse visible={show} >
                        <div className="navdrop">

                            <ul className="list-group">
                                <li className="list-group-item"><a onClick={() => {
                                    navi("/home")
                                    dispatch(seteditmybooking(null))
                                }}>Book Ride</a></li>
                                <li className="list-group-item"><a onClick={() => navi("/mybookings")}>Mybookings</a></li>
                                <li className="list-group-item"><a onClick={() => navi("/profile")}>MyProfile</a></li>
                                <li className="list-group-item" onClick={() => navi("/address")}><a>Saved Address</a></li>
                                <li className="list-group-item" onClick={() => navi("/usersupport")}><a>Support</a></li>
                                <li className="list-group-item" onClick={logout}><a>Logout</a></li>
                            </ul>

                        </div>
                    </CCollapse>
                </>)}
            </div>


            {user && <Notificationcounter usertype={"passenger"} email={user?.email} route={"/notification"} />}


        </>)

}

export default Myheader;