
import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import logo from ".././Components/img/logo-main.png";
import { DirectionsCarFilled, Person } from "@mui/icons-material";



function LoginType() {
    const navi = useNavigate();

    return (<>


        <div className="logintype">
            <div style={{ display: "flex", justifyContent: "center", padding: "20px 0px"}}>
                <img src={logo} />
            </div>


            <div>
                <Typography variant="h5" sx={{ textAlign: "center", fontFamily: "cursive", padding: "100px 0px",color:"white" }}>
                    Choose Your Login Type
                </Typography>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>

                <div style={{ display: "flex", width: "500px", justifyContent: 'space-around' }}>
                    <Button sx={{fontSize:{xs:11,sm:16}}} variant="contained" size="large" color="secondary" onClick={() => navi("/rider")}>
                        <DirectionsCarFilled/>&nbsp;Driver Login
                    </Button>
                    
                    <Button sx={{fontSize:{xs:11,sm:16}}} variant="contained" size="large" color="primary" onClick={() => navi("/signin")}>
                    <Person/>&nbsp;Passenger Login
                    </Button>
                </div>

            </div>
        </div>

    </>)
}

export default LoginType;