import { CardContent, Divider, Grid } from "@material-ui/core";
import { Button, Card, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Driverheader from "./Driverheader";
import { Add } from "@mui/icons-material";
import { country } from "../Allapis";
import axios from "axios";
import { auth } from "../Firebase";
import firebase from "firebase";
import GooglePlaces from "../Components/GooglePlaces";
import { Apiurl } from "../api_urls";
import { useDispatch, useSelector } from "react-redux";
import { riderlogin } from "../redux_store/RiderloginSlice";
import { GToaster } from "../Components/g_toaster";



const toster = new GToaster()
function Driveredit() {
    const editdata = useSelector((state) => state?.data?.riderdata?.editriderdetails);
    const rideruser = useSelector((state) => state?.data?.riderdata?.rider)

    const objdata = {
        "name": "",
        "email": "",
        "mobile": "",
        "country_code": "",
        "gender": "",
        "age": "",
        "address": null,
        "license_number": "",

        "going_morning": null,
        "going_afternoon": null,
        "going_evening": null,
        "going_night": null,

        "return_morning": null,
        "return_afternoon": null,
        "return_evening": null,
        "return_night": null,

        "vehicle_number": "",
        "vehicle_color": "",
        "vehicle_rc": "",
        "vehicle_make": "",
        "vehicle_model": "",
    }

    const data = editdata ? { ...editdata } : { ...objdata }
    const [obj, setobj] = useState({ ...data })

    const [otpdata, setotpdata] = useState(null)
    const [country_code, setcountry_code] = useState(country)
    const [oldmobile, setoldmobile] = useState(editdata?.mobile)
    const [editaddress, seteditaddress] = useState(false)
    const [alladdress, setalladdress] = useState([])
    const [btnloader, setbtnloader] = useState(false)


    const dispatch = useDispatch()
    const navi = useNavigate()

    async function getrideraddress() {
        await axios.get(`${Apiurl.GET_PARTICULAR_DRIVER_ADDRESSES_URL}${rideruser.id}`).then((res) => {
            if (res.data.status === 200) {
                let newdata = res.data.addresses
                setalladdress([...newdata])

            }
        }).catch((err) => {
            toster.error({ title: "Something Went Wrong in Pickup Addresses fetch" })
        })
    }




    function gopicadress(e, zip, city, label,lat,long) {
        let mydata = JSON.parse(JSON.stringify(obj))
        let myobj = {
            ['id']: 0, ["address"]: label, ["tag"]: "Home", ["postal_code"]: zip, ["city"]: city, ['type']: "pickup",
            ["lat"]: lat,
            ["long"]: long,
        }
        mydata["address"] = myobj
        setobj({ ...mydata })
    }


    function handlechange(key, value) {
        setobj({ ...obj, [key]: value })
    }

    function verify(e) {
        e.preventDefault();
        setbtnloader(true)
        var data = new FormData(e.currentTarget)
        let Otp = data.get("ver")
        otpdata.confirm(Otp).then((succ) => {
            updaterider(obj)
        }).catch((err) => {
            toster.error({ title: "Otp is not verify please check it otherwise reload the page" })
        })
    }

    function resend() {
        const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible'
        });
        const phoneNumber = `${obj.country_code}${obj.contact_number}`;
        auth.signInWithPhoneNumber(phoneNumber, appVerifier)
            .then((confirmationResult) => {
                setotpdata(confirmationResult);
                toster.success({ title: "Otp is send to your registered mobile number please verify" })
            }).catch((error) => {
                toster.error({ title: "something went wrong please reload your page again" });
            })
    }

    function drivereditdata(e) {
        e.preventDefault();
        setbtnloader(true)
        if (obj?.mobile === oldmobile) {
            updaterider(obj)
        } else {
            const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible'
            });
            const phoneNumber = `${obj?.country_code}${obj?.mobile}`;
            auth.signInWithPhoneNumber(phoneNumber, appVerifier)
                .then((confirmationResult) => {
                    setotpdata(confirmationResult);
                    setbtnloader(false)
                    toster.success({ title: "Otp is send to your registered mobile number please verify" })
                }).catch((error) => {
                    toster.error({ title: "something went wrong please reload your page again" });
                })
        }

    }

    async function updaterider(data) {
        await axios.post(`${Apiurl.UPDATE_DRIVER_URL}`, data).then((succ) => {
            if (succ.data.status === 200) {
                dispatch(riderlogin(succ.data.driver))
                setbtnloader(false)
                localStorage.removeItem("driver")
                localStorage.removeItem("drivername")
                toster.success({ title: "Data successfully updated" })
                navi("/riderprofile")
                window.location.reload()
            } else {
                toster.error({ title: succ.data.data })
                setbtnloader(false)
            }
        }).catch((err) => {
            toster.error({ title: "something went wrong! Data not updated" })
            setbtnloader(false)
        })
    }



    useEffect(() => {
        getrideraddress()

    }, [])




    return (
        <>
            <Driverheader />

            <Grid container>

                {otpdata ? (<>

                    <Grid item lg={5} md={6} sm={10} xs={12} style={{ margin: "auto", marginTop: "10px" }}>
                        <CardContent>
                            <Card>
                                <CardContent>
                                    <CardContent>
                                        <Typography align="center" variant="h4">Verify Your Otp</Typography>
                                        <p style={{ textAlign: "center", color: "grey" }}>Enter  your six-digit otp that will receive on your  Contact No. If you have any issue about otp receive then <a >contact us.</a></p>

                                    </CardContent>

                                    <form onSubmit={verify}>
                                        <CardContent>
                                            <input type={"text"} className="form-control serinput" name="ver" />
                                        </CardContent>

                                        <CardContent>
                                            {btnloader ? <CircularProgress /> : <Button size="large" type="submit" variant="contained" >Verify Otp</Button>}
                                            <Button size="large" type="submit" variant="contained" color="secondary" sx={{ float: "right" }} onClick={resend}>Resend Otp</Button>
                                        </CardContent>
                                    </form>

                                    <div style={{ display: "none" }} id="recaptcha-container"></div>

                                </CardContent>
                            </Card>
                        </CardContent>
                    </Grid>
                </>) : (<>


                    <Grid item lg={8} md={11} sm={11} xs={12} style={{ margin: "auto", marginTop: "10px" }}>
                        <CardContent>
                            <Card>

                                <Grid container>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <CardContent>
                                            <Typography variant="h4">Edit Profile Data</Typography>
                                            <Typography variant="subtitle1">The information can be edited</Typography>
                                            <Divider />

                                            <form onSubmit={drivereditdata}>
                                                <Grid container className="profileinput" spacing={4}>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <Typography variant="h5" sx={{ py: 1 }}>Personal Details</Typography>
                                                    </Grid>

                                                    <Grid item lg={4} md={6} sm={6} xs={12} className="inputpad" >
                                                        <label className="form-label">Name</label>
                                                        <input required className="form-control" type="text" name="name" value={obj?.name} onChange={(e) => handlechange(e.target.name, e.target.value)} />
                                                    </Grid>


                                                    <Grid item lg={4} md={6} sm={6} xs={12} className="inputpad"   >
                                                        <label className="form-label">Email</label>
                                                        <input required className="form-control" type="email" name="email" value={obj?.email} onChange={(e) => handlechange(e.target.name, e.target.value)} />
                                                    </Grid>


                                                    <Grid item lg={1} md={2} sm={2} xs={4} className="inputpad">
                                                        <label className="form-label">Country</label>
                                                        <select className="form-select form-control form-select-lg " required name="country_code" value={obj?.country_code} onChange={(e) => handlechange(e.target.name, e.target.value)}>
                                                            <option defaultValue={" "}></option>
                                                            {country_code.map((row) => (
                                                                <React.Fragment key={row.name}>
                                                                    <option value={row.dial_code}>{row.dial_code}  {row.name}</option>
                                                                </React.Fragment>
                                                            ))}
                                                        </select>
                                                    </Grid>

                                                    <Grid item lg={3} md={4} sm={4} xs={8} className="inputpad">
                                                        <label className="form-label">Phone Number</label>
                                                        <input required className="form-control" type="text" name="mobile" minLength={10} value={obj?.mobile} onChange={(e) => handlechange(e.target.name, e.target.value)} />
                                                    </Grid>


                                                    <Grid item lg={6} md={6} sm={6} xs={12} className="inputpad"   >
                                                        <label className="form-label">Age</label>
                                                        <input required className="form-control" type="number" name="age" value={obj?.age} onChange={(e) => handlechange(e.target.name, parseFloat(e.target.value))} />
                                                    </Grid>

                                                    <Grid item lg={6} md={6} sm={6} xs={12} className="inputpad"   >
                                                        <label className="form-label">License Number</label>
                                                        <input required className="form-control" type="text" name="license_number" value={obj?.license_number} onChange={(e) => handlechange(e.target.name, e.target.value)} />
                                                    </Grid>


                                                    <Grid item lg={6} md={6} sm={6} xs={12} className="inputpad"   >
                                                        <label className="form-label">Gender</label>
                                                        <select className="form-select form-control form-select-lg" required name="gender" value={obj?.gender} onChange={(e) => handlechange(e.target.name, e.target.value)}>
                                                            <option defaultValue={" "}></option>
                                                            <option value={"male"} >Male</option>
                                                            <option value={"female"} >Female</option>
                                                        </select>

                                                    </Grid>




                                                    <Grid item lg={6} md={6} sm={12} xs={12} className="inputpad">
                                                        <label className="form-label">{editaddress ? "Address" : "Select Address"}</label>
                                                        <span onClick={() => seteditaddress(!editaddress)} style={{ color: "#117fb7", paddingLeft: "18px", fontSize: "16px", cursor: "pointer", fontWeight: "bolder" }}><u><Add />{editaddress ? "Old" : "New"} Address</u></span>

                                                        {editaddress ? (<>
                                                            <GooglePlaces gopicadress={gopicadress} label={"gopicadress"} />
                                                        </>) : (<>
                                                            <select className="form-select form-control" name="address" required id="pickadress" value={JSON.stringify(obj?.address)} onChange={(e) => handlechange(e.target.name, JSON.parse(e.target.value))}>
                                                                <option defaultValue={""} className="form-control" ></option>
                                                                {alladdress.map((row) => {
                                                                    return (<React.Fragment key={row.id}>
                                                                        <option value={JSON.stringify(row)} >
                                                                            {row.address}
                                                                        </option>
                                                                    </React.Fragment>)
                                                                })}
                                                            </select>
                                                        </>)}

                                                    </Grid>

                                                    {/* <Grid item lg={12} md={12} sm={12} xs={12} className="headingcase">
                                                        <Typography variant="h5" sx={{ py: 2 }}>In which shift you are available?</Typography>
                                                    </Grid>


                                                    <Grid item lg={3} md={3} sm={6} xs={12} className="inputpad"   >

                                                        <label className="form-label">Going Morning</label>
                                                        <select className="form-select form-control form-select-lg" name="going_morning" value={obj?.going_morning} onChange={(e) => handlechange(e.target.name, e.target.value)} >
                                                            <option defaultValue={" "}>Select Going Morning</option>
                                                            {morningtimes.length > 0 && <>
                                                                <option value={"all"} >All</option>
                                                                {morningtimes.map((ele, index) => (
                                                                    <option key={index} value={ele}>{ele}</option>
                                                                ))}
                                                            </>
                                                            }
                                                        </select>

                                                    </Grid>


                                                    <Grid item lg={3} md={3} sm={6} xs={12} className="inputpad"   >

                                                        <label className="form-label">Going Afternoon</label>
                                                        <select className="form-select form-control form-select-lg" name="going_afternoon" value={obj?.going_afternoon} onChange={(e) => handlechange(e.target.name, e.target.value)}>
                                                            <option defaultValue={" "}>Select Going Afternoon</option>
                                                            {noontimes.length > 0 && <>
                                                                <option value={"all"} >All</option>
                                                                {noontimes.map((ele, index) => (
                                                                    <option key={index} value={ele}>{ele}</option>
                                                                ))}
                                                            </>
                                                            }
                                                        </select>

                                                    </Grid>


                                                    <Grid item lg={3} md={3} sm={6} xs={12} className="inputpad"   >

                                                        <label className="form-label">Going Evening</label>
                                                        <select className="form-select form-control form-select-lg" name="going_evening" value={obj?.going_evening} onChange={(e) => handlechange(e.target.name, e.target.value)}>
                                                            <option defaultValue={" "}>Select Going Evening</option>
                                                            {eveningtimes.length > 0 && <>
                                                                <option value={"all"} >All</option>
                                                                {eveningtimes.map((ele, index) => (
                                                                    <option key={index} value={ele}>{ele}</option>
                                                                ))}
                                                            </>
                                                            }
                                                        </select>

                                                    </Grid>


                                                    <Grid item lg={3} md={3} sm={6} xs={12} className="inputpad"   >

                                                        <label className="form-label">Going Night</label>
                                                        <select className="form-select form-control form-select-lg" name="going_night" value={obj?.going_night} onChange={(e) => handlechange(e.target.name, e.target.value)}>
                                                            <option defaultValue={" "}>Select Going Night</option>
                                                            {nighttimes.length > 0 && <>
                                                                <option value={"all"} >All</option>
                                                                {nighttimes.map((ele, index) => (
                                                                    <option key={index} value={ele}>{ele}</option>
                                                                ))}
                                                            </>
                                                            }
                                                        </select>

                                                    </Grid>



                                                    <Grid item lg={3} md={3} sm={6} xs={12} className="inputpad"   >

                                                        <label className="form-label">Return Morning</label>
                                                        <select className="form-select form-control form-select-lg" name="return_morning" value={obj?.return_morning} onChange={(e) => handlechange(e.target.name, e.target.value)}>
                                                            <option defaultValue={" "}>Select Return Morning</option>
                                                            {morningtimes.length > 0 && <>
                                                                <option value={"all"} >All</option>
                                                                {morningtimes.map((ele, index) => (
                                                                    <option key={index} value={ele}>{ele}</option>
                                                                ))}
                                                            </>
                                                            }
                                                        </select>

                                                    </Grid>


                                                    <Grid item lg={3} md={3} sm={6} xs={12} className="inputpad"   >

                                                        <label className="form-label">Return Afternoon</label>
                                                        <select className="form-select form-control form-select-lg" name="return_afternoon" value={obj?.return_afternoon} onChange={(e) => handlechange(e.target.name, e.target.value)}>
                                                            <option defaultValue={" "}>Select Return Afternoon</option>
                                                            {noontimes.length > 0 && <>
                                                                <option value={"all"} >All</option>
                                                                {noontimes.map((ele, index) => (
                                                                    <option key={index} value={ele}>{ele}</option>
                                                                ))}
                                                            </>
                                                            }
                                                        </select>

                                                    </Grid>


                                                    <Grid item lg={3} md={3} sm={6} xs={12} className="inputpad"   >

                                                        <label className="form-label">Return Evening</label>
                                                        <select className="form-select form-control form-select-lg" name="return_evening" value={obj?.return_evening} onChange={(e) => handlechange(e.target.name, e.target.value)}>
                                                            <option defaultValue={" "}>Select Return Evening</option>
                                                            {eveningtimes.length > 0 && <>
                                                                <option value={"all"} >All</option>
                                                                {eveningtimes.map((ele, index) => (
                                                                    <option key={index} value={ele}>{ele}</option>
                                                                ))}
                                                            </>
                                                            }
                                                        </select>

                                                    </Grid>


                                                    <Grid item lg={3} md={3} sm={6} xs={12} className="inputpad"   >

                                                        <label className="form-label">Return Night</label>
                                                        <select className="form-select form-control form-select-lg" name="return_night" value={obj?.return_night} onChange={(e) => handlechange(e.target.name, e.target.value)} >
                                                            <option defaultValue={" "}>Select Return Night</option>
                                                            {nighttimes.length > 0 && <>
                                                                <option value={"all"} >All</option>
                                                                {nighttimes.map((ele, index) => (
                                                                    <option key={index} value={ele}>{ele}</option>
                                                                ))}
                                                            </>
                                                            }
                                                        </select>

                                                    </Grid> */}


                                                    <Grid item lg={12} md={12} sm={12} xs={12} className="headingcase">
                                                        <Typography variant="h5" sx={{ py: 1 }}>Vehicle Details</Typography>
                                                    </Grid>


                                                    <Grid item lg={12} xs={12} className="inputpad"   >
                                                        <label className="form-label">Vehicle Color</label>
                                                        <input className="form-control" type="text" name="vehicle_color" value={obj?.vehicle_color} onChange={(e) => handlechange(e.target.name, e.target.value)} />
                                                    </Grid>


                                                    <Grid item lg={4} md={6} sm={6} xs={12} className="inputpad"   >

                                                        <label className="form-label">Vehicle Rc Number</label>
                                                        <input required className="form-control" type="text" name="vehicle_rc" value={obj?.vehicle_rc} onChange={(e) => handlechange(e.target.name, e.target.value)} />

                                                    </Grid>

                                                    <Grid item lg={4} md={6} sm={6} xs={12} className="inputpad"   >

                                                        <label className="form-label">Vehicle Model</label>
                                                        <input className="form-control" type="text" name="vehicle_model" value={obj?.vehicle_model} onChange={(e) => handlechange(e.target.name, e.target.value)} />

                                                    </Grid>

                                                    <Grid item lg={4} md={6} sm={12} xs={12} className="inputpad"   >

                                                        <label className="form-label">Vehicle Number</label>
                                                        <input required className="form-control" type="text" name="vehicle_number" value={obj?.vehicle_number} onChange={(e) => handlechange(e.target.name, e.target.value)} />

                                                    </Grid>

                                                    <Grid item lg={12} md={12} sm={12} xs={12}  >
                                                        {btnloader ? <CircularProgress /> :
                                                            <button className="default-btn btn-two mt-3" style={{ background: "white" }} type="submit">
                                                                Update Details
                                                            </button>
                                                        }
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <CardContent>
                                                            <CardContent>
                                                                <div style={{ display: "none" }} id="recaptcha-container"></div>
                                                            </CardContent>
                                                        </CardContent>
                                                    </Grid>

                                                </Grid>
                                            </form>
                                        </CardContent>
                                    </Grid>
                                </Grid>
                            </Card>
                        </CardContent>
                    </Grid>

                </>)}

            </Grid>


        </>
    )

}

export default Driveredit;