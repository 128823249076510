import { createSlice } from "@reduxjs/toolkit";

const BookingDataSlice = createSlice({
    name: "BookingDataSlice",
    initialState: {
        bookingdata: [],
        cuttimeout: null,
        editbookindata: null,
    },
    reducers: {
        getbookingdata(state, action) {
            state.bookingdata = action.payload
        },

        gettimecutdata(state, action) {
            state.cuttimeout = action.payload
        },

        seteditmybooking(state, action) {
            state.editbookindata = action.payload
        }

    }
})

export default BookingDataSlice.reducer
export const { getbookingdata, gettimecutdata,seteditmybooking } = BookingDataSlice.actions