import { Button, Card, CardContent, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Apiurl } from "../api_urls";
import { loginuser } from "../redux_store/LoginSlice";
import { useDispatch } from "react-redux";
import { GToaster } from "./g_toaster";
import { riderlogin } from "../redux_store/RiderloginSlice";


const toster = new GToaster()
function TngLogin() {


    const [btnloader, setbtnloader] = useState(false)
    const [driver, setdriver] = useState(false);
    const url = Apiurl.USER_LOGIN_URL
    const navi = useNavigate()
    const dispatch = useDispatch()
    async function submitdata(e) {
        e.preventDefault()
        let data = new FormData(e.currentTarget)
        let n = data.get("name")
        let phone = data.get("phone")
        let obj = { contact_number: phone }
        localStorage.removeItem("persist:root")
        setbtnloader(true)
        // console.log(n,phone)

        if (n == "mukesh") {

            if (driver) {

                await axios.post(Apiurl.DRIVER_LOGIN_URL, obj).then((response) => {
                    if (response.data.status == 300) {
                        toster.warning({ title: "This contact no. is not registered please check your contact" })
                        setbtnloader(false)
                    } else if (response.data.status == 202) {
                        toster.warning({ title: "Your account status is inactive please contact the admin" })
                        setbtnloader(true)
                    } else if (response.data.status == 200) {
                        dispatch(riderlogin(response.data.data))
                        toster.success({ title: "login Successfully" })
                        navi("/riderprofile")
                        setbtnloader(true)
                    } else {
                        toster.error({ title: "Something went wrong in login user" })
                        setbtnloader(true)
                    }
                }).catch((err) => {
                    toster.error({ title: "Something went wrong in login user" })
                    setbtnloader(true)
                })

            } else {

                axios.post(url, obj).then((response) => {
                    if (response.data.status == 400) {
                        alert("This contact no. is not registered please check your contact")
                        setbtnloader(true)
                    } else if (response.data.status == 200) {
                        if (response.data.data.status == 0) {
                            alert("Your account status is inactive please contact the admin")
                            setbtnloader(true)
                        } else {
                            dispatch(loginuser(response.data.data))
                            alert("login success")
                            navi("/mybookings")
                            setbtnloader(true)
                        }
                    }
                }).catch((err) => {
                    setbtnloader(true)
                    console.log(err)
                })
            }
        } else {
            alert("you are not authorised this is for admin use")
            setbtnloader(true)
        }
    }

    return (
        <>

            <Grid container spacing={1} sx={{ m: "auto" }}>
                <Grid item xs={10} sm={6} md={5} sx={{ m: "auto", mt: 20 }}>
                    <Card>
                        <CardContent>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", py: 1 }}>
                                <Button variant="contained" color="primary" onClick={() => setdriver(true)}>Rider login</Button>
                                <Button variant="contained" color="secondary" onClick={() => setdriver(false)}>Passanger</Button>
                            </Grid>

                            <Typography variant="h5" sx={{ p: 2 }}>Tng login form </Typography>
                            <form onSubmit={submitdata}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <TextField fullWidth name="name" placeholder="name" required />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField fullWidth name="phone" placeholder={driver ? "Rider Phone" : "User Phone"} required />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {btnloader ? <CircularProgress /> : <Button fullWidth variant="contained" type="submit">submit</Button>}
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>



        </>
    )


}


export default TngLogin