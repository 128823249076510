import { Cancel } from '@mui/icons-material'
import { Button } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import GooglePlacesAutocomplete, { geocodeByLatLng, geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete'

const GooglePlaces = ({ gopicadress, label, godropvalue, retdropaddress, rtpicvalue }) => {
  const [value, setValue] = useState(null)
  async function getaddress(e) {
    geocodeByPlaceId(e.value.place_id).then((succ) => {
      // console.log(succ)
      getLatLng(succ[0]).then(({ lat, lng }) => {

        let obj = { ...e, ["label"]: succ[0].formatted_address }
        let splitaddress = succ[0].formatted_address.split(",")
        let postal_code = splitaddress[2].trim().slice(3,splitaddress[2].length).trim()
        let city = splitaddress[1].trim()
        setValue({ ...obj })
        if (label == "gopicadress") {
          gopicadress(obj, postal_code,city, obj?.label,lat,lng)
        } else if (label == "godropvalue") {
          godropvalue(obj, postal_code, city, obj?.label,lat,lng)
        } else if (label == "retdropaddress") {
          retdropaddress(obj, postal_code, city, obj?.label,lat,lng)
        } else if (label == "rtpicvalue") {
          rtpicvalue(obj, postal_code, city, obj?.label,lat,lng)
        }
      });
    })



  }


  return (


    <div className="row">
      <div className="col-10 pe-0">
        <GooglePlacesAutocomplete
          apiKey={process.env.GOOGLE_ADDRESS_API_KEY}
          debounce={200}
          autocompletionRequest={{
            componentRestrictions: {
              country: ["ca"]
            },
          }}
          selectProps={{
            value,
            onChange: getaddress,

            styles: {
              input: (provided) => ({
                ...provided,
                color: 'black',
                fontSize: "15px",
                height: "33px",
                borderRadius: "6px",
                focus: "2px solid #f2eeed",

              }),
              option: (provided) => ({
                ...provided,
                color: 'black',
                fontSize: "15px",
                background: "pick"
              }),
              singleValue: (provided) => ({
                ...provided,
                color: 'black',
              }),
            },

          }}

          onLoadFailed={(error) => (
            console.error("Could not inject Google script", error)
          )}
        />


      </div>
      <div className="col-1 ps-0">
        <Button onClick={() => {
          setValue(null)
          if (label == "gopicadress") {
            gopicadress(null, "", "", "")
          } else if (label == "godropvalue") {
            godropvalue(null, "", "", "")
          } else if (label == "retdropaddress") {
            retdropaddress(null, "", "", "")
          } else if (label == "rtpicvalue") {
            rtpicvalue(null, "", "", "")
          }
        }} className="input-button" sx={{ border: "1px solid white", height: "42px", width: "20px" }} variant="outlined" size="small">
          <Cancel />
        </Button>

      </div>
    </div>
  )
}

export default GooglePlaces