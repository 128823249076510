import { Cancel } from '@mui/icons-material';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import React, { useState } from 'react'

const Gmodal = ({ handledata,closemodal }) => {

let styleOfModal = {
        position: "relative",
        m: "auto",
        mt: 45,
        width: { sm: 600, xs: 370 },
        bgcolor: handledata?.modaltype == "success" ? "green" : "red",
        textAlign: 'center',
        boxShadow: 22,
        borderRadius:4,
        py: 2,
        px:2
    }

function handleclose(){
    closemodal(false)
}


    return (
        <Modal
            open={handledata?.modalshow}
            onClose={handleclose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={styleOfModal}>
                <IconButton sx={{ position: "absolute", top: "10px", right: "10px", color: "white" }} onClick={handleclose}>
                    <Cancel />
                </IconButton>
                <Typography id="modal-modal-title" variant="h5" sx={{ color: "white" }} component="h2">
                    {handledata?.modaltype == "success" ? "Success!" : "Warning!"}
                </Typography>
                <Typography id="modal-modal-description" variant='h6' sx={{ color: "white", textShadow: "1px 0px 5px black" }}>
                    { handledata?.modalmessage}
                </Typography>
            </Box>
        </Modal >

    );
}

export default Gmodal